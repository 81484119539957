export const environment = {
    production: true,
    local: false,
    HOST_URL: 'https://jioevents.com/',
    BASE_URL: 'https://jioevents.com/api',
    WS_URL: 'wss://jioevents.com/ws',
    CONF_URL: 'https://jioevents.com/',
    JIO_CLOUD: {
        HOST: 'https://api.jiocloud.com',
        HOST_UPLOAD: 'https://uplaod.jiocloud.com',
        API_KEY: '64bb6ba3-f62a-4a17-b52e-0726aecaef15',
        APP_SECRET: 'NGY0ZDlkYWYtN2QxNS00MDY3LWI0NTgtNWM5YWI2ZmI4ZWI5'
    },
    DIGNOSTIC_API_KEY: '8f7022f4-2d52-473e-938d-26c062b3caf5',
    DIGNOSTIC_API_SECRET: '020e86573ab900f245a4b46c5fce063df0fee684d094df5c784ff20243ad',
    HAPTIC_CLIENT_ID: 'b607c0682a8b90a849a416699e8916680be48ba2'
};

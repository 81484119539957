import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { SIGNIN } from 'src/app/constants/gtm-events';
import {
    AuthService,
    ValidationService,
    AppService,
    RememberMeService,
    UtilService,
    GaDiagnosticService,
    DiagnosticService,
    LocalStorageService,
    UserService,
    DesktopAppService
} from './../../core';
import { REG_EX } from 'src/app/constants';
import { TranslateService } from '@ngx-translate/core';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { WebinarService } from 'src/app/dashboard-webinars/services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
    mailSent: boolean;
    isJioPc: boolean = false;
    userDataExist: boolean;
    emailDomain: string;
    isGetEventForDomainValidation: any;
    constructor(
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private router: Router,
        private toastrService: ToastrService,
        private appService: AppService,
        private authService: AuthService,
        private validationService: ValidationService,
        private rememberMeService: RememberMeService,
        private gaService: GaDiagnosticService,
        private diagnosticService: DiagnosticService,
        private utilService: UtilService,
        private localStorageService: LocalStorageService,
        private translateService: TranslateService,
        private googleTagMangerService: GoogleTagMangerService,
        private userService: UserService,
        private webinarService: WebinarService,
        private appLoggerSercie: AppLoggerService,
        private desktopAppService: DesktopAppService
    ) {}

    otp: string;
    showOtpComponent = true;
    @ViewChild('ngOtpInput') ngOtpInput: any;
    config = {
        allowNumbersOnly: true,
        length: 6,
        isPasswordInput: false,
        disableAutoFocus: false,
        placeholder: '',
        inputClass: 'otp-input-class',
        containerClass: 'otp-container-class'
    };
    usernameForm: FormGroup;
    loginForm: FormGroup;
    ottForm: FormGroup;
    errors: any = {};
    rememberMe = false;
    loggingIn = false;
    showPassword = false;
    childWindow = null;
    samlLoginInProgress = false;
    isDomainEmail = false;
    emailLogin = false;
    ottLogin = false;
    requestingOTP = false;
    sessionId;
    token;
    success;
    enableProceedBtn = false;
    bot;
    outlook;
    google;
    googleRedirectURI;
    returnUrl;
    userNotFound = false;
    loading = true;
    isLoginFromLandingPage = false;
    @Output() closePopup = new EventEmitter();

    ngOnInit() {
        this.isJioPc = this.utilService.isJioPcCheck();
        if (!this.router.url.includes('login')) {
            this.isGetEventForDomainValidation = this.localStorageService.getItem('isGetEvent');
        }
        this.activatedRoute.queryParams.subscribe((params) => {
            this.sessionId = params.sessionId;
            this.token = params.token;
            this.success = params.success;
            this.bot = params.bot;
            this.outlook = params.outlook;
            this.google = params.google;
            this.googleRedirectURI = params.googleRedirectURI;
            if (this.bot === 'true') {
                sessionStorage.setItem('bot', 'true');
            }
            if (this.outlook === 'true') {
                // Session storage does not work as expected in IE and Safari
                this.localStorageService.addItem('outlook', 'true');
            }
            this.returnUrl = params.returnUrl;
            if (this.returnUrl) {
                sessionStorage.setItem('returnUrl', this.returnUrl);
            }

            if (this.google === 'true') {
                sessionStorage.setItem('google', 'true');
                sessionStorage.setItem('googleRedirectURI', this.googleRedirectURI);
            }
        });
        this.isLoginFromLandingPage = this.activatedRoute.snapshot.params?.hash ? true : false;
        this.usernameForm = this.fb.group({
            username: ['', [Validators.required]]
        });
        this.loginForm = this.fb.group({
            email: [null, [Validators.required]],
            password: [null, [Validators.required]],
            rememberMe: [false]
        });
        this.ottForm = this.fb.group({
            phoneNo: [null, [Validators.required, Validators.pattern(/^\d{10}$/)]],
            otp: [null],
            rememberMe: [false]
        });

        if (this.sessionId) {
            if (!this.success || this.success === 'false') {
                this.errors.global = 'Login failed. Please try again later.';
                this.samlLoginInProgress = false;
                this.loading = false;
                return;
            }
            this.samlLoginInProgress = true;
            this.authService.getSamlAccessToken(this.sessionId, this.token).subscribe(
                () => {
                    this.samlLoginInProgress = false;
                    this.navigateToLandingPage();
                },
                (err) => {
                    if (err.error && err.error.errors) {
                        this.errors.global = err.error.errors;
                    } else {
                        this.errors.global = 'Something went wrong. Please try again.';
                    }
                    this.samlLoginInProgress = false;
                    this.loggingIn = false;
                }
            );
            return;
        }
        if (this.appService.isMyJio) {
            this.router.navigate(['/integrations/myjio/home']);
        }

        if (this.authService.getIsAuthenticated()) {
            this.navigateToLandingPage();
            return;
        }

        const rememberedUserData = this.rememberMeService.get('rememberMe');
        if (rememberedUserData) {
            // this.loginForm.get('email').setValue(rememberedUserData.email);
            this.usernameForm.get('username').setValue(rememberedUserData.username);
            this.loginForm.get('rememberMe').setValue(true);

            this.checkForDomainEmail(rememberedUserData.username);
            this.enableProceedBtn = this.validateUsername(rememberedUserData.username, true);
        }

        this.loginForm.get('email').valueChanges.subscribe((email) => {
            this.checkForDomainEmail(email);
        });

        this.usernameForm
            .get('username')
            .valueChanges // .pipe(pairwise())
            .subscribe((next) => {
                this.checkForDomainEmail(next);
                this.enableProceedBtn = this.validateUsername(next, false);
            });

        this.gaService.sendPageView({
            page_title: 'login_page',
            page_path: '/login'
        });

        this.loading = false;
    }

    checkForDomainEmail(email, cb?) {
        this.isDomainEmail = false;
        if (this.validationService.isEmail(email)) {
            const providerName = this.appService
                .getConfigVariable('SAML_DOMAINS')
                .includes(this.utilService.getDomainFromEmail(email))
                ? 'saml'
                : this.utilService.getDomainFromEmail(email);
            this.authService.isDomainUser({ providerName }).subscribe(() => {
                this.isDomainEmail = true;
                if (cb) {
                    cb(true);
                }
            });
        }
    }

    validateUsername(value = '', reportError = true) {
        const val = value || this.usernameForm.value.username;
        this.errors.global = false;
        this.errors.username = false;
        if (!(this.validationService.isEmail(val) || val.match(/^\d{10}$/))) {
            if (reportError) {
                this.errors.username = 'Please enter valid email or phoneNo.';
            }
            return false;
        }
        return true;
    }

    onEmailChanged(event) {
        this.emailLogin = false;
        this.usernameForm.get('username').setValue(event?.target?.value);
    }

    submitForSpectrum(userDetails) {
        this.checkForDomainEmail(userDetails.emailId, () => {
            this.usernameForm.get('username').setValue(userDetails.emailId);
            this.isDomainEmail = true;
            this.submitUsername();
        });
    }

    getDomainFromEmail = (email: string) => {
        const pos = email?.search('@');
        if (pos > 0) {
            this.emailDomain = email?.slice(pos + 1);
        }
        return this.emailDomain;
    };
    launchAuthUrl(res) {
        if (this.desktopAppService.isDesktopApp) {
            this.desktopAppService.launchAuthWindow(res.authUrl, (url) => {
                console.log(`auth callback url is ${url}`);
                this.validateAndGoToLanding(url);
            });
        } else {
            window.location.href = res.authUrl;
        }
    }

    validateAndGoToLanding(url: string) {
        var parsedUrl = new URL(url);
        this.sessionId = parsedUrl.searchParams.get('sessionId');
        this.token = parsedUrl.searchParams.get('token');
        this.success = parsedUrl.searchParams.get('success');
        if (!this.success || this.success === 'false') {
            this.errors.global = 'Login failed. Please try again later.';
            this.samlLoginInProgress = false;
            this.loading = false;
            return;
        }
        this.samlLoginInProgress = true;
        this.authService.getSamlAccessToken(this.sessionId, this.token).subscribe(
            () => {
                this.samlLoginInProgress = false;
                this.navigateToLandingPage();
            },
            (err) => {
                if (err.error && err.error.errors) {
                    this.errors.global = err.error.errors;
                } else {
                    this.errors.global = 'Something went wrong. Please try again.';
                }
                this.samlLoginInProgress = false;
                this.loggingIn = false;
            }
        );
        return;
    }

    submitUsername() {
        const domainID = this.getDomainFromEmail(this.usernameForm.value.username.toLowerCase());
        if (
            !this.appService?.getConfigVariable('GET_DOMAINS')?.includes(domainID) &&
            this.isGetEventForDomainValidation
        ) {
            this.toastrService.info('Kindly use your Enterprise account to join the event');
            return false;
        }
        this.googleTagMangerService.pushGoogleTagData(SIGNIN.PROCEED);
        this.userNotFound = false;
        this.errors = {};
        if (!this.validateUsername()) {
            return;
        }
        if (this.validationService.isEmail(this.usernameForm.value.username)) {
            if (this.isDomainEmail) {
                if (
                    this.appService
                        .getConfigVariable('SAML_DOMAINS')
                        .includes(this.utilService.getDomainFromEmail(this.usernameForm.value.username))
                ) {
                    this.authService
                        .doSSOLogin({
                            queryParams: {
                                email: this.usernameForm.value.username,
                                bot: this.bot,
                                hostInfo: null,
                                outlook: false
                            },
                            externalIntegrationParams: {
                                google: this.google,
                                googleRedirectURI: this.googleRedirectURI
                            },
                            redirectRoute: 'login'
                        })
                        .subscribe((res) => {
                            if (this.desktopAppService.isDesktopApp) {
                                this.launchAuthUrl(res);
                            } else {
                                window.location.href = res.authUrl;
                            }
                        });
                } else {
                    this.authService
                        .doSSOLogin({
                            providerName: this.utilService.getDomainFromEmail(this.usernameForm.value.username),
                            queryParams: {
                                email: null,
                                bot: this.bot,
                                hostInfo: null,
                                outlook: false
                            },
                            externalIntegrationParams: {
                                google: this.google,
                                googleRedirectURI: this.googleRedirectURI
                            },
                            redirectRoute: 'login'
                        })
                        .subscribe((res) => {
                            if (this.desktopAppService.isDesktopApp) {
                                this.launchAuthUrl(res);
                            } else {
                                window.location.href = res.authUrl;
                            }
                        });
                }
                return;
            }
            this.authService.isOTTUser({ email: this.usernameForm.value.username }).subscribe(
                (res: any) => {
                    this.emailLogin = true;
                    this.loginForm.patchValue({
                        email: this.usernameForm.value.username
                    });
                },
                (err) => {
                    if (err.error.message === 'USERNOTFOUND') {
                        this.userNotFound = true;
                        this.signup();
                    } else {
                        this.errors.global = err.error.errors;
                    }
                }
            );

            return;
        }
        if (this.usernameForm.value.username.match(/^\d{10}$/)) {
            this.authService.isOTTUser({ phoneNo: this.usernameForm.value.username }).subscribe(
                (res: any) => {
                    this.sendOTP(this.usernameForm.value.username);
                },
                (err) => {
                    if (err.error.message === 'USERNOTFOUND') {
                        this.userNotFound = true;
                        this.sendOTP(this.usernameForm.value.username);
                    } else {
                        this.errors.global = err.error.errors;
                    }
                }
            );
        }
    }

    validate() {
        this.errors = {};

        if (!this.validationService.isEmail(this.loginForm.value.email)) {
            this.errors.email = 'Please enter a valid email';
            return false;
        }
        if (!this.isDomainEmail && !this.loginForm.value.password) {
            this.errors.password = 'Password cannot be blank';
            return false;
        }
        return true;
    }

    submitEmailPassword() {
        if (!this.validate()) {
            return;
        }

        if (this.loginForm.value.rememberMe) {
            this.rememberMeService.set('rememberMe', {
                username: this.loginForm.value.email
            });
        } else {
            this.rememberMeService.remove('rememberMe');
        }

        if (this.isDomainEmail) {
            if (
                this.appService
                    .getConfigVariable('SAML_DOMAINS')
                    .includes(this.utilService.getDomainFromEmail(this.loginForm.value.email))
            ) {
                this.authService
                    .doSSOLogin({
                        queryParams: {
                            email: this.loginForm.value.email,
                            bot: this.bot,
                            hostInfo: null,
                            outlook: false
                        },
                        externalIntegrationParams: {
                            google: this.google,
                            googleRedirectURI: this.googleRedirectURI
                        },
                        redirectRoute: 'login'
                    })
                    .subscribe((res) => {
                        if (this.desktopAppService.isDesktopApp) {
                            this.launchAuthUrl(res);
                        } else {
                            window.location.href = res.authUrl;
                        }
                    });
            } else {
                this.authService
                    .doSSOLogin({
                        providerName: this.utilService.getDomainFromEmail(this.loginForm.value.email),
                        queryParams: {
                            email: null,
                            bot: this.bot,
                            hostInfo: null,
                            outlook: false
                        },
                        externalIntegrationParams: {
                            google: this.google,
                            googleRedirectURI: this.googleRedirectURI
                        },
                        redirectRoute: 'login'
                    })
                    .subscribe((res) => {
                        if (this.desktopAppService.isDesktopApp) {
                            this.launchAuthUrl(res);
                        } else {
                            window.location.href = res.authUrl;
                        }
                    });
            }
        } else {
            this.loggingIn = true;
            this.authService.login(this.loginForm.value).subscribe(
                (res) => {
                    this.loggingIn = false;
                    if (res.success === false) {
                        if (res.message === 'INVALIDCREDENTIALS') {
                            this.errors.global = 'Incorrect Email ID or Password';
                        } else {
                            this.errors.global = res.errors;
                            if (res.message === 'ACTIVATIONERROR') {
                                this.toastrService.info(
                                    'We have sent a verification link to your registered Email. Please verify to login.'
                                );
                            }
                        }
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Jioconf Login',
                                eventAction: 'Sign In with Email',
                                eventType: 'api_failure',
                                endpoint: '/api/user/login',
                                status: 'failed',
                                data: res
                            })
                            .subscribe();
                        return;
                    }
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Login',
                            eventAction: 'Sign In with Email',
                            eventType: 'app_event',
                            endpoint: '/api/user/login',
                            status: 'success',
                            data: { email: this.loginForm.value.email }
                        })
                        .subscribe();
                    this.navigateToLandingPage();
                },
                (err) => {
                    this.errors.global = this.translateService.instant('tostrs.something_went_wrong_please_try_again');
                    this.loggingIn = false;
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Login',
                            eventAction: 'Sign In with Email',
                            eventType: 'api_failure',
                            endpoint: '/api/user/login',
                            status: 'failed'
                        })
                        .subscribe();
                }
            );
        }
    }

    signup() {
        this.loggingIn = true;
        this.authService
            .signup({
                emailId: this.usernameForm.value.username,
                name: 'JioEventsUser',
                lname: 'JioEventsUser'

                // name: this.signupForm.value.name?.trim(),
                // lname: this.signupForm.value.lname?.trim(),
                // code: this.invitecode
            })
            .subscribe(
                (res: any) => {
                    this.mailSent = true;
                    this.loggingIn = false;
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign Up',
                            eventAction: 'Sign Up with Email',
                            eventType: 'app_event',
                            endpoint: '/api/sign/signup',
                            status: 'success',
                            data: { email: res.email }
                        })
                        .subscribe();
                },
                (err) => {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign Up',
                            eventAction: 'Sign Up with Email',
                            eventType: 'api_failure',
                            endpoint: '/api/sign/signup',
                            status: 'failed',
                            data: err.error
                        })
                        .subscribe();

                    this.loggingIn = false;
                    if (err.error?.customCode === 421) {
                        this.handleUnverifiedAccount();
                    } else {
                        if (
                            err?.error?.errors ===
                            this.translateService.instant('signin_page.already_registered_check_you_mail')
                        ) {
                            this.resendEmail();
                            this.errors.global = this.translateService.instant(
                                'signin_page.already_registered_check_your_mail'
                            );
                        } else if (err?.error?.errors) {
                            this.errors.global = err.error.errors;
                        } else {
                            this.errors.global = this.translateService.instant(
                                'tostrs.something_went_wrong_please_try_again'
                            );
                        }
                    }
                }
            );
    }

    handleUnverifiedAccount() {
        // this.unVerifiedAccount = true;
        this.resendEmail();
        // this.toastrService.info(
        //     this.translateService.instant('tostrs.account_verification_link_has_been_sent_to_your_email')
        // );
        this.mailSent = true;
    }

    disableOttLoginForm() {
        if (!(this.ottForm.value.otp && (this.ottForm.value.otp || '').match(/^\d{6}$/))) {
            return true;
        }
        return false;
    }

    verifyOTP() {
        this.googleTagMangerService.pushGoogleTagData(SIGNIN.VERIFY_OTP);
        if (this.ottForm.value.rememberMe) {
            this.rememberMeService.set('rememberMe', {
                username: this.ottForm.value.phoneNo
            });
        } else {
            this.rememberMeService.remove('rememberMe');
        }

        this.loggingIn = true;
        this.authService
            .verifyOTP({
                phoneNo: this.ottForm.value.phoneNo,
                otp: this.ottForm.value.otp
            })
            .subscribe(
                (res: any) => {
                    this.loggingIn = false;
                    if (res.success === false || res.errors || res.message === 'OTPAUTHERROR') {
                        res.message === 'OTPAUTHERROR'
                            ? (this.errors.otp = 'Incorrect OTP')
                            : (this.errors.global = res.errors);
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Jioconf Login',
                                eventAction: 'Verify OTP',
                                eventType: 'api_failure',
                                endpoint: '/api/verifyotp',
                                status: 'failed',
                                data: res
                            })
                            .subscribe();
                        return;
                    }
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Login',
                            eventAction: 'Verify OTP',
                            eventType: 'app_event',
                            endpoint: '/api/verifyotp',
                            status: 'success',
                            data: { phoneNo: res.phoneNo }
                        })
                        .subscribe();
                    if (res.token) {
                        this.authService
                            .verifyOtpDualLogin({
                                phoneNo: this.ottForm.value.phoneNo,
                                otp: this.ottForm.value.otp,
                                token: res.token
                            })
                            .subscribe(() => {
                                this.navigateToLandingPage();
                            });
                    } else {
                        this.navigateToLandingPage();
                    }
                },
                (err) => {
                    err.error.message === 'OTPAUTHERROR'
                        ? (this.errors.otp = 'Incorrect OTP')
                        : err.error.message === 'LIMITEXCEEDED'
                        ? (this.errors.otp = 'Too many attempts')
                        : (this.errors.global = err.errors);
                    this.loggingIn = false;
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Login',
                            eventAction: 'Verify OTP',
                            eventType: 'api_failure',
                            endpoint: '/api/verifyotp',
                            status: 'failed'
                        })
                        .subscribe();
                }
            );
    }

    resendOTP() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Jioconf Login',
                eventAction: 'Resend OTP',
                eventType: 'app_event',
                endpoint: '/api/getotp',
                status: 'success'
            })
            .subscribe();
        this.getOTP();
    }

    changePhoneNo() {
        this.ottForm.reset();
        this.requestingOTP = true;
    }

    getOTP() {
        this.errors = {};
        if (!this.ottForm.value.phoneNo.match(/^\d{10}$/)) {
            this.errors.phoneNo = 'Please enter valid 10 digit mibile no.';
            return;
        }

        this.sendOTP(this.ottForm.value.phoneNo);
        this.googleTagMangerService.pushGoogleTagData(SIGNIN.RESEND_OTP);
    }

    private sendOTP(phoneNo) {
        this.loggingIn = true;
        this.authService
            .getOtp({
                phoneNo
            })
            .subscribe(
                (res: any) => {
                    this.loggingIn = false;
                    if (res.status === 'success') {
                        this.ottLogin = true;
                        this.requestingOTP = false;
                        this.ottForm.patchValue({
                            phoneNo
                        });
                    }
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Login',
                            eventAction: 'Sign In with Mobile',
                            eventType: 'app_event',
                            endpoint: '/api/getotp',
                            status: 'success',
                            data: { phoneNo: res.phoneNo }
                        })
                        .subscribe();
                },
                (err) => {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Login',
                            eventAction: 'Sign In with Mobile',
                            eventType: 'api_failure',
                            endpoint: '/api/getotp',
                            status: 'failed',
                            data: err.error
                        })
                        .subscribe();
                    if (err.error.customCode === 400) {
                        this.errors.global = 'Please try again after 15 minutes.';
                        this.loggingIn = false;
                        return;
                    }
                    if (err.error.message === 'INVALID_PHONE_NO') {
                        this.toastrService.error('Please enter valid email or phoneNo.');
                    } else if (err.error && err.error.errors) {
                        this.errors.global = err.error.errors;
                    } else {
                        this.errors.global = err.error;
                    }
                    this.loggingIn = false;
                }
            );
    }

    showInviteCodePopup() {
        this.router.navigate(['/signup'], { relativeTo: this.activatedRoute });
    }

    navToForgotPassword() {
        this.router.navigate(['forgot-password/v1']);
    }

    loginWithSSO(providerName) {
        this.errors = {};
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Jioconf Login',
                eventType: 'app_event',
                eventAction: `Sign In with ${providerName}`,
                endpoint: `/api/authorize/${providerName}`,
                status: 'success'
            })
            .subscribe();

        this.authService
            .doSSOLogin({
                providerName,
                queryParams: {
                    email: null,
                    bot: this.bot,
                    hostInfo: null,
                    outlook: false
                },
                externalIntegrationParams: {
                    google: this.google,
                    googleRedirectURI: this.googleRedirectURI
                },
                redirectRoute: 'login'
            })
            .subscribe((res) => {
                if (this.desktopAppService.isDesktopApp) {
                    this.launchAuthUrl(res);
                } else {
                    window.location.href = res.authUrl;
                }
            });
        if (providerName == 'google') {
            this.googleTagMangerService.pushGoogleTagData(SIGNIN.GOOGLE_LOGO);
        } else {
            this.googleTagMangerService.pushGoogleTagData(SIGNIN.FACEBOOK_LOGO);
        }
        this.googleRedirectURI();
    }

    launchTnc() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Terms and Conditions',
                status: 'success'
            })
            .subscribe();
        window.open(this.appService.getJioEventsBaseUrl() + 'terms-condition-business');
    }

    launchPrivacy() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Privacy Policy',
                status: 'success'
            })
            .subscribe();
        window.open(this.appService.getJioEventsBaseUrl() + 'privacy-policy-business');
    }

    navigateToLandingPage() {
        this.userService.getUser(true).subscribe(
            async (user) => {
                if (sessionStorage.getItem('returnUrl')) {
                    let userDetailsExist;
                    const isGetEvent = this.localStorageService.getItem('isGetEvent');
                    if (isGetEvent) {
                        userDetailsExist = await this.webinarService.checkIfUserDetailsExist();
                    }
                    const isAttendee = this.localStorageService.getItem('isAttendee');
                    if (!userDetailsExist && isGetEvent && isAttendee) {
                        this.router.navigate(['/get-user-details']);
                    } else {
                        this.returnUrl = sessionStorage.getItem('returnUrl');
                        sessionStorage.removeItem('returnUrl');
                        if (!this.appService.isDesktopApp()) this.router.navigateByUrl(this.returnUrl);
                        else this.appService.reloadAppContent(this.returnUrl);
                    }
                } else {
                    if (!this.appService.isDesktopApp()) this.router.navigate(['/dashboard']);
                    else this.appService.reloadAppContent('/dashboard');
                }
            },
            (err) => {
                this.appLoggerSercie.error(err);
            }
        );
    }

    navigateToSignUp() {
        this.router.navigate(['signup']);
    }

    userAnotherEmail() {
        this.errors = {};
        this.ottLogin = false;
        this.usernameForm.reset();
    }
    togglePassword() {
        this.showPassword = !this.showPassword;
    }

    close() {
        this.closePopup.emit();
        if (sessionStorage.getItem('returnUrl')) {
            this.returnUrl = sessionStorage.getItem('returnUrl');
            sessionStorage.removeItem('returnUrl');
            this.router.navigateByUrl(this.returnUrl);
        }
    }

    editEmail() {
        this.mailSent = false;
        // this.ottLogin = false;
        // this.signupForm.reset();
        // this.ottForm.reset();
    }

    resendEmail() {
        this.authService.resendEmail(this.usernameForm.value.username).subscribe(
            () =>
                this.toastrService.info(
                    this.translateService.instant(
                        'tostrs.we_have_sent_a_verification_link_to_your_registered_email_please_verify_to_login'
                    ),
                    null,
                    { toastClass: 'notify-login-failed toast-info toast-close-button', closeButton: true }
                ),
            () => this.toastrService.error(this.translateService.instant('tostrs.resend_failed_please_try_again_later'))
        );
    }

    navigateToLogin() {
        this.usernameForm.reset();
        this.mailSent = false;
        this.enableProceedBtn = false;
    }

    handeOtpChange(otp) {
        this.ottForm.get('otp').setValue(otp);
    }

    goToPp() {
        // this.router.navigate(['privacy-policy-business']);
        window.open(`${this.appService.getJioEventsBaseUrl()}privacy-policy-business`);
        this.googleTagMangerService.pushGoogleTagData(SIGNIN.PRIVACY_POLICY);
    }

    goToTnc() {
        // this.router.navigate(['terms-condition-business']);
        window.open(`${this.appService.getJioEventsBaseUrl()}terms-condition-business`);
        this.googleTagMangerService.pushGoogleTagData(SIGNIN.TERMS_OF_USE);
    }
}

import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { ConfirmationService } from 'primeng/api';

import {
    UserService,
    AuthService,
    EventEmitterService,
    EventData,
    UtilService,
    AppService,
    LocalStorageService,
    DesktopAppService
} from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { APP_EVENTS, WEB_CONSTANT, HEADER, HOME_PAGE_REVAMP, DASHBOARD_NAV_LINKS } from '../../../constants';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { Subscription } from 'rxjs';
import { WebinarService } from 'src/app/dashboard-webinars/services';

@Component({
    selector: 'app-header-version2',
    templateUrl: './header-version2.component.html',
    styleUrls: ['./header-version2.component.scss']
})
export class HeaderVersion2Component implements OnInit, OnDestroy {
    currentUser;
    loading = false;
    profilePicPath;
    isAuthenticated = false;
    showMenu;
    isDefaultPic: boolean;
    isMobile;
    headerSettings = {
        isDarkTheme: false,
        showUseCase: true,
        showSignIn: true,
        showCreateEvent: false,
        showDashboard: false
    };
    isMobileLandscapeMode;
    tooltip = `You don't have access to create an event`;
    @Input() hasTransparentHeader: boolean = false;
    @Input() isDashboard: boolean = false;
    currentScrollTop: number = 0;
    private prevScrollTop: any = 0;
    loginPage: any = '/login';
    public topHeaderVisibility: boolean = true;
    enableNewSignIn: boolean = this.appService.getConfigVariable('enableNewSignIn');
    isStaticPage: boolean = false;
    staticPages: any = WEB_CONSTANT.STATIC_PAGES;
    public showInMobile = false;
    headerEvents: any = HEADER;
    activeColorState: boolean = false;
    activeIconState: boolean = false;
    showNavigationList: boolean = false;
    newVersion: boolean;
    dashboardNavLinks = DASHBOARD_NAV_LINKS;
    currentRoute: string = '';
    isDropdownOpen: boolean = false;
    showProfile: boolean = false;
    isIpad: boolean = false;
    isIOS: boolean = false;
    buttonText;
    pageLogoUrl = '';
    contactPage: boolean = false;
    isStudioHeader: boolean;
    private eventEmitterSubscription: Subscription;
    hasNavigatedToAccount = false;
    isEventControls: boolean = false;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private userService: UserService,
        private confirmationService: ConfirmationService,
        private eventEmitterService: EventEmitterService,
        public utilService: UtilService,
        private translate: TranslateService,
        private toastrService: ToastrService,
        public appService: AppService,
        private _el: ElementRef,
        private localstoreService: LocalStorageService,
        private googleTagManger: GoogleTagMangerService,
        private renderer: Renderer2,
        private webinarService: WebinarService,
        private desktopAppService: DesktopAppService
    ) {
        this.loginPage = this.appService.getLoginPageUrl();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = this.utilService.isResponsiveMobileView(1180);
        this.updateButtonText();
        if (window.innerWidth < 1181) {
            this.showInMobile = true;
        } else {
            this.showInMobile = false;
        }
    }

    setPageLogoUrl() {
        this.isStudioHeader = window.location.href.includes('events-studio');
        this.pageLogoUrl = this.isStudioHeader
            ? 'assets/studio-assets/studio-logo.svg'
            : this.isMobile || this.showInMobile
            ? 'assets/img/website/mobile_website_icon.svg'
            : 'assets/img/website/website_logo.svg';
    }

    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event) {
        let isLandscape =
            window.screen.orientation.type === 'landscape-primary' ||
            window.screen.orientation.type === 'landscape-secondary';
        if (this.utilService.isSafariBrowser() || this.utilService.isMobileIOSBrowser()) {
            //Since safari is not giving results for window matchMedia Queries
            isLandscape = window.innerHeight < window.innerWidth;
        } else {
            isLandscape = window.matchMedia('(orientation: landscape)').matches;
        }
        this.isMobileLandscapeMode = this.isMobile && isLandscape;
    }

    ngOnInit() {
        this.webinarService.eventControlsPopup$.subscribe((isEventControlsFlag) => {
            this.isEventControls = isEventControlsFlag;
        });
        this.isMobile = this.utilService.isResponsiveMobileView(1180);
        this.isIpad = this.utilService.isIpad();
        this.isIOS = this.utilService.isMobileIOSBrowser();
        this.currentUser = this.userService.getUserSync();
        this.updateButtonText();
        this.hideButtonOnContactPage(this.router.url);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.hideButtonOnContactPage(event.url);
            }
            this.setPageLogoUrl();
        });
        if (this.currentUser._id) {
            this.profilePicPath = this.userService.getProfilePicPath(this.currentUser._id);
        }

        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });

        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.UPDATE_PROFILE || event.type === APP_EVENTS.NAME_CHANGED) {
                this.currentUser = this.userService.getUserSync();
                this.profilePicPath = this.userService.getProfilePicPath(this.currentUser._id);
                this.currentUser = this.userService.getUserSync();
                this.isDefaultPic = !this.currentUser?.imageMeta && !this.currentUser?.imageMeta?.path;
            }
        });
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.setHeaderOptions(event.url);
        });
        this.setHeaderOptions(this.router.url);
        if (window.innerWidth < 1181) {
            this.showInMobile = true;
        }

        this.setPageLogoUrl();
        this.eventEmitterSubscription = this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === 'gotoAccount') {
                if (!this.hasNavigatedToAccount) {
                    this.router.navigate(['pricing']);
                    this.openProfile();
                    this.hasNavigatedToAccount = true;
                }
            }
        });
    }

    toggleDropDown(name: string) {
        if (name == 'product') {
            this.activeIconState = !this.activeIconState;
            HOME_PAGE_REVAMP.DROP_DOWN_CLICK.clicked_text = name;
            this.googleTagManger.pushGoogleTagData(HOME_PAGE_REVAMP.DROP_DOWN_CLICK);
        } else if (name == 'resource') {
            this.activeColorState = !this.activeColorState;
            HOME_PAGE_REVAMP.DROP_DOWN_CLICK.clicked_text = name;
            this.googleTagManger.pushGoogleTagData(HOME_PAGE_REVAMP.DROP_DOWN_CLICK);
        } else {
            this.activeColorState = false;
            this.activeIconState = false;
        }
    }
    getProfilePicPath() {
        return this.userService.getProfilePicPath(this.currentUser._id);
    }

    updateButtonText() {
        this.buttonText = window.innerWidth < 360 ? 'Demo' : 'Request Demo';
    }

    hideButtonOnContactPage(url: string) {
        this.contactPage = url.includes('/contactus');
    }

    navigateToHome() {
        if (this.showMenu) {
            this.showMenu = false;
        }
        if (this.appService.isDesktopApp()) return;
        this.router.navigate(['/home'], { relativeTo: this.activatedRoute });
        setTimeout(() => {
            this.utilService.scrollToAnchor('website-top', 50);
            this.googleTagManger.pushGoogleTagData(HOME_PAGE_REVAMP.HOME_BUTTON_CLICK);
        }, 100);
    }
    closeProfile() {
        this.showProfile = false;
    }
    openProfile() {
        this.isDropdownOpen = false;
        this.showProfile = true;
    }

    navigateToSignIn() {
        this.toggleMenu();
        this.router.navigate(['/login'], { relativeTo: this.activatedRoute });
    }

    closeProfilePopUp() {
        if (!document.getElementsByClassName('dropdown-menu-footer')[0]) return;
        document.getElementsByClassName('dropdown-menu-footer')[0]?.classList.remove('show');
        document.getElementsByClassName('background-overlay')[0]?.remove();
    }

    navigateUsingUrl(endpoint) {
        let url = '/' + `${endpoint}`;
        this.router.navigateByUrl(url);
    }

    navigateToProfile() {
        this.toggleMenu();
        this.googleTagManger.pushGoogleTagData(this.headerEvents.PROFILE);
        this.router.navigate(['/profile'], { relativeTo: this.activatedRoute });
    }

    navigateToDashboard(type = true) {
        this.toggleMenu();
        if (type) this.googleTagManger.pushGoogleTagData(this.headerEvents.DASHBOARD);
        this.router.navigate(['dashboard']);
    }

    createEvent() {
        const canCreateWebinar = this.utilService.enablePaymentFeature(this.currentUser?.isOTT)
            ? this.currentUser?.eventsFeaturesEnabled?.canCreateWebinar
            : this.currentUser.canCreateWebinar;
        if (!canCreateWebinar) {
            this.localstoreService.addItem('userFrom', 'dashboard');
            this.router.navigate(['/contactus']);
        }
        this.toggleMenu();
        this.router.navigate(['/dashboard'], {
            relativeTo: this.activatedRoute,
            queryParams: { create: true }
        });
    }

    logout() {
        this.showMenu = false;
        this.translate.get(['sign_out_popup']).subscribe((translations) => {
            this.confirmationService.confirm({
                message: translations.sign_out_popup.text,
                header: translations.sign_out_popup.title,
                acceptLabel: translations.sign_out_popup.acceptLabel,
                rejectLabel: translations.sign_out_popup.rejectLabel,
                acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
                rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
                accept: () => {
                    this.googleTagManger.pushGoogleTagData(this.headerEvents.SIGN_OUT);
                    this.authService.logout().subscribe(() => {
                        if (this.desktopAppService.isDesktopApp) {
                            this.desktopAppService.reload(null);
                        }
                    });
                }
            });
        });
    }

    toggleMenu() {
        this.showMenu = !this.showMenu;
        if (this.isMobile) {
            // emit event to stop scroll on parent screen
            this.eventEmitterService.emit({ type: APP_EVENTS.MOBILE_HEADER_MENU_CLICK, data: null });
            this.closeProfilePopUp();
        }
    }
    toggleVersionClick() {
        if (!this.desktopAppService.isDesktopApp) {
            this.router.navigate(['/home']).then(() => {
                this.utilService.isOldVersion = false;
                this.googleTagManger.pushGoogleTagData(HOME_PAGE_REVAMP.TRY_NEW_LOOK);
            });
        }
    }

    onOutsideClick(event) {
        this.showMenu = false;
        if (!this.isMobile) {
            this.activeColorState = false;
            this.activeIconState = false;
        }
    }
    navigateToUseCases() {
        this.utilService.scrollToAnchor('use-cases', 50);
    }

    closeToggle(eventData, e = null) {
        if (eventData) this.googleTagManger.pushGoogleTagData(eventData);
        this._el.nativeElement.querySelector('.mobile-collapse').classList.toggle('show');
        this.toggleMenu();
        this.closeProfile();
        this.isDropdownOpen = false;
        if (e) {
            e.stopPropagation();
        }
    }

    setHeaderOptions(route) {
        this.currentRoute = route;
        this.headerSettings.isDarkTheme = route === '/home' ? true : false;
        this.headerSettings.showUseCase = route === '/home' ? true : false;
        this.headerSettings.showSignIn = route === '/home' ? true : false;
        this.headerSettings.showDashboard = route === '/home' ? true : false;
        if (this.staticPages.includes(route)) {
            this.isStaticPage = true;
        } else {
            this.isStaticPage = false;
        }
        if (route.includes('dashboard')) {
            this.isStaticPage = this.utilService.isResponsiveMobileView(1180);
        }
        this.headerSettings.showCreateEvent = !(
            route === '/home' ||
            route === '/onboarding' ||
            route.includes('join') ||
            route.includes('preview') ||
            route.includes('attendee')
        )
            ? true
            : false;
    }

    redirectToLogin() {
        this.router.navigate(['/login']).then(() => {
            this.googleTagManger.pushGoogleTagData(HOME_PAGE_REVAMP.LOGIN);
        });
    }

    redirectToContactUs() {
        this.onOutsideClick(null);
        const currentPage = window.location?.pathname;
        const sourcePageMapping = {
            '/home': 'https://jioevents.com/home',
            '/pricing': 'https://jioevents.com/pricing',
            '/solutions': 'https://jioevents.com/solutions',
            '/explore-events': 'https://jioevents.com/explore-events',
            '/customer': 'https://jioevents.com/customer'
        };
        const sourcePage = sourcePageMapping[currentPage] || window.location.href;

        if (window.location?.pathname === '/contactus') {
            window.location.reload();
        } else {
            this.router.navigate(['/contactus']);
        }
        const newJsonObject = { ...HOME_PAGE_REVAMP.SCHEDULE_DEMO_HEADER, source_page: sourcePage };
        this.googleTagManger.pushGoogleTagData(newJsonObject);
    }
    redirectToWhatsNew() {
        this.utilService.redirectToWhatsNew();
    }

    redirectToBlog() {
        if (this.appService.getConfigVariable('JIOEVENT_BLOG_URL')) {
            window.open(this.appService.getConfigVariable('JIOEVENT_BLOG_URL'));
        } else {
            this.router.navigateByUrl('/comingsoon');
        }
    }
    navigateToFaqs() {
        this.router.navigateByUrl('/faqs');
    }

    redirectToCaseStudies() {
        if (this.appService.getConfigVariable('JIOEVENT_CASE_STUDIES_URL')) {
            window.open(this.appService.getConfigVariable('JIOEVENT_CASE_STUDIES_URL'));
        } else {
            this.router.navigateByUrl('/comingsoon');
        }
    }

    handleHeaderHambergerClick() {
        if (this.isMobile || this.showInMobile) {
            const elements = document.querySelector('.hamburger');

            if (elements.classList.contains('is-active')) {
                this.renderer.removeClass(elements, 'is-active');
            } else {
                this.renderer.addClass(elements, 'is-active');
            }
            this.showNavigationList = !this.showNavigationList;
        } else {
            this.showNavigationList = true;
        }
    }
    navigateTohome() {
        if (this.appService.isDesktopApp()) return;
        this.router.navigate(['/home']);
        var navbar = document.getElementById('navbar1');
        navbar.classList.remove('darkHeader');
    }

    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
        this._el.nativeElement.querySelector('.mobile-collapse').classList.remove('show');
        this.showMenu = false;
    }

    ngOnDestroy(): void {
        if (this.eventEmitterSubscription) {
            this.eventEmitterSubscription.unsubscribe();
        }
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppService, DesktopAppService } from 'src/app/core';
import { nativeImage } from 'electron';
import * as _ from 'lodash-es';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

const SHOW_LIVE_STREAMS = true;

@Component({
    selector: 'app-screen-capture-selection',
    templateUrl: './screen-capture-selection.component.html',
    styleUrls: ['./screen-capture-selection.component.scss']
})
export class ScreenCaptureSelectionComponent implements OnInit, OnDestroy {
    windowStreams;
    isLoading = false;
    showStreamVidep = false;
    selectedStream: any;
    tabs = [
        { id: 0, title: 'Window', enabled: true, tkey: 'screen-share.window' },
        { id: 1, title: 'Entire Screen', enabled: false, tkey: 'screen-share.entire_screen' }
    ];
    activeTab;
    selectionType = [
        { id: 0, label: 'Window', enabled: true, tkey: 'screen-share.window' },
        { id: 1, label: 'Entire Screen', enabled: true, tkey: 'screen-share.entire_screen' }
    ];
    activeType;
    constructor(
        private desktopAppService: DesktopAppService,
        private ref: DynamicDialogRef,
        private appService: AppService
    ) {
        this.showStreamVidep = this.appService.getConfigVariable('ENABLE_SCREEN_STREAM') || false;
    }

    ngOnInit(): void {
        this.prepareData();
        this.setTab(this.tabs[0]);
        this.setSelection();
    }

    setSelection(id = 0) {
        this.activeType = this.selectionType[id];
    }

    async prepareData() {
        this.isLoading = true;

        var sources = await this.desktopAppService
            .getScreenCaptureSources({
                types: ['window', 'screen'],
                thumbnailSize: { width: 1280, height: 720 },
                fetchWindowIcons: true
            })
            .then(async (srcs) => {
                var wstreams = [];
                for (let index = 0; index < srcs.length; index++) {
                    const source = srcs[index];
                    if (SHOW_LIVE_STREAMS) {
                        wstreams.push(
                            this.desktopAppService.prepareStream(source).then((stream) => {
                                return {
                                    stream,
                                    source
                                };
                            })
                        );
                    } else {
                        // TODO get thumbnails
                        wstreams.push({ source });
                    }
                }
                this.windowStreams = await Promise.all(wstreams);
                this.isLoading = false;
            });
    }

    cancel() {
        this.ref.close();
    }

    isActiveTab(tab) {
        return tab === this.activeTab;
    }
    setTab(tab) {
        this.activeTab = tab;
    }

    onStreamSelected(ws) {
        this.selectedStream = ws;
    }

    share() {
        if (SHOW_LIVE_STREAMS) {
            this.selectedStream.stream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        this.ref.close(this.selectedStream.source);
    }

    ngOnDestroy(): void {
        if (SHOW_LIVE_STREAMS) {
            this.windowStreams.forEach((ws) => {
                ws.stream.getTracks().forEach((track: MediaStreamTrack) => {
                    track.stop();
                });
            });
        }
    }
}

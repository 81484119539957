import { Injectable, isDevMode } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppService, DesktopAppService } from '..';

const API = 'api';
const HOST_URL = 'HOST_URL';
@Injectable()
export class DesktopAppApiInterceptor implements HttpInterceptor {
    hostUrl: string;
    isProduction: boolean;
    constructor(private desktopAppService: DesktopAppService, private appService: AppService) {
        this.hostUrl = this.appService.getEnvVariable('HOST_URL');
        this.isProduction = this.appService.getEnvVariable('production');
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let url = request.url.replace(/^.*\/\/[^\/]+:?[0-9]?\//i, '');
        this.handleDesktopRequest(request, url);
        return next.handle(request);
    }

    handleDesktopRequest(request: HttpRequest<unknown>, url: any) {
        if (!this.desktopAppService.isDesktopApp) return;
        if (this.isEnvironmentSelectedOrWhiteboardUrl(url)) {
            this.reassignEnvironment(request, url);
        } else if (!isDevMode() && request.url.startsWith(API)) {
            request = request.clone({ url: `${this.hostUrl}${request.url}` });
        }
    }

    reassignEnvironment(request: HttpRequest<unknown>, url: any) {
        if (isDevMode()) {
            request = request.clone({
                url: `${this.hostUrl}${this.desktopAppService?.selectedEnvironment?.value}${'_'}${url}`
            });
        } else {
            switch (this.desktopAppService?.selectedEnvironment?.value) {
                case 'rc':
                    this.hostUrl = this.desktopAppService.rcEnvironmentConfig[HOST_URL];
                    break;
                case 'prestage':
                    this.hostUrl = this.desktopAppService.prestageEnvironmentConfig[HOST_URL];
                    break;
                case 'prod':
                    this.hostUrl = this.desktopAppService.prodEnvironmentConfig[HOST_URL];
                    break;
            }
            request = request.clone({ url: `${this.hostUrl}${url}` });
        }
    }

    isEnvironmentSelectedOrWhiteboardUrl(url: any) {
        return (
            this.appService?.config?.ShowEnvironmentSelectionPopup &&
            url.startsWith(`${API}`) &&
            this.desktopAppService?.selectedEnvironment?.value
        );
    }
}

import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { APP_EVENTS } from 'src/app/constants';
import { EXPLORE_EVENTS } from 'src/app/constants/gtm-events';
import { EventViewSection } from 'src/app/constants/webinar-enum';
import { AuthService, EventData, EventEmitterService, UtilService } from 'src/app/core';
import { ExploreEventsService } from 'src/app/core/services/explore-events.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';

@Component({
    selector: 'app-explore-events',
    templateUrl: './explore-events.component.html',
    styleUrls: ['./explore-events.component.scss']
})
export class ExploreEventsComponent implements OnInit {
    readonly EventViewSection = EventViewSection;
    @Input() dashboard;

    @ViewChild('eventTypeList') eventTypeListRef: ElementRef;
    @ViewChild('filterForm') eventTypeFiltertRef: ElementRef;

    @Output() DropDownFooter = new EventEmitter<any>();
    @Output() FilterTrigger = new EventEmitter<any>();

    /*  @ViewChild('mySelect') selectElementRef: ElementRef; */
    cardContent: boolean = false;
    filters = false;
    allEvents = false;
    eventDay = 'all';
    publishType = 'all';
    eventDayState = 'all';
    publishTypeState = 'all';
    eventType = 'all';
    favourites = false;
    publishTypeFilters = [
        {
            name: 'Public',
            gtmValue: 'filter_public'
        },
        {
            name: 'Organisation',
            gtmValue: 'filter_organisation'
        }
    ];
    eventDayFilters = [
        {
            name: 'Today',
            gtmValue: 'filter_today'
        },
        {
            name: 'This Month',
            gtmValue: 'filter_month'
        }
    ];
    eventTypeFilters = [
        {
            name: 'Technology',
            gtmValue: 'filter_technology'
        },
        {
            name: 'Education',
            gtmValue: 'filter_education'
        },
        {
            name: 'Career Fair',
            gtmValue: 'filter_career_fair'
        },
        {
            name: 'Professional Development',
            gtmValue: 'filter_professional_development'
        },
        {
            name: 'Health & Lifestyle',
            gtmValue: 'filter_health_and_lifestyle'
        },
        {
            name: 'Business & Entrepreneurship',
            gtmValue: 'filter_business_and_entrepreneurship'
        },
        {
            name: 'Marketing & Advertising',
            gtmValue: 'filter_marketing_and_advertising'
        },
        {
            name: 'Entertainment',
            gtmValue: 'filter_entertainment'
        },
        {
            name: 'Community & Spirituality',
            gtmValue: 'filter_community_and_spirituality'
        },
        {
            name: 'Judiciary'
        },
        {
            name: 'Travel & Tourism'
        },
        {
            name: 'Custom'
        }
    ];
    isFavouritesLoading = false;
    trendingEventsLength;
    recommendedEventsLength;
    ongoingEventsLength;
    upcomingEventsLength;
    replayEventsLength;
    favouriteEventsList = [];
    searchQueryEvents = '';
    search = '';
    searchModelChanged: Subject<string> = new Subject<string>();
    isLoading;
    isMobile = false;
    isAuthenticated;
    currentPage: any = 'explore_events_page';
    filergtmLabel: any = {
        eventDay: '',
        eventType: ''
    };
    scroll: number;
    filterOptions = [];
    filterOptionsObject: any;
    currentRoute: string = '/dashboard';
    headerRoute: string = '/explore-events';
    dashboardData = [];
    currentMeeting = null;
    showCalendar = false;
    isSafariBrowser = false;
    constructor(
        private exploreEventsService: ExploreEventsService,
        public utilService: UtilService,
        private authservice: AuthService,
        private googleTagManager: GoogleTagMangerService,
        private router: Router,
        private eventEmitterService: EventEmitterService,
        private activatedRoute: ActivatedRoute
    ) {
        this.activatedRoute.data.subscribe((route) => {
            this.currentRoute = this.activatedRoute['_routerState'].snapshot.url.split('?')[0];
            this.dashboard = false; //this.activatedRoute['_routerState'].snapshot.url.split('/')[2] || false;
        });
        this.activatedRoute.data.subscribe((route) => {
            this.headerRoute = this.activatedRoute['_routerState'].snapshot.url.split('?')[0];
            this.dashboard = false;
        });
    }

    ngOnInit(): void {
        this.isMobile = this.utilService.isResponsiveMobileView(1079);
        this.isSafariBrowser = this.utilService.isSafariBrowser();
        this.isAuthenticated = this.authservice.getIsAuthenticated();
        if (this.dashboard) {
            this.currentPage = 'dashboard_explore_events';
        }
        this.searchModelChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((model) => {});
        this.isLoading = true;
        forkJoin([
            this.exploreEventsService.getEventsList('trending', 'all', 'all', 'all', '', 0, 10),
            this.exploreEventsService.getEventsList('recommended', 'all', 'all', 'all', '', 0, 10),
            this.exploreEventsService.getEventsList('ongoing', 'all', 'all', 'all', '', 0, 10),
            this.exploreEventsService.getEventsList('upcoming', 'all', 'all', 'all', '', 0, 10),
            this.exploreEventsService.getEventsList('replay', 'all', 'all', 'all', '', 0, 10)
        ]).subscribe((responseList) => {
            this.sendData(responseList);
        });
        this.subscribeToEventEmitter();
    }

    subscribeToEventEmitter() {
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.OPEN_ADD_TO_CAL) {
                this.currentMeeting = event.data?.meeting;
                this.showCalendar = true;
            } else if (event.type === APP_EVENTS.CLOSE_ADD_TO_CAL_CLICK) {
                this.currentMeeting = null;
                this.showCalendar = false;
            }
        });
    }

    handleSearchAction(model: string) {
        this.changeCurrentPageForGA4(Object.assign(EXPLORE_EVENTS.EXPLORE_SEARCH, { current_page: this.currentPage }));
        this.searchQueryEvents = model;
        this.search = model;
        this.callListApi();
    }

    callListApi() {
        this.filters = false;
        this.allEvents = false;
        if (!this.favourites) {
            this.isLoading = true;

            forkJoin([
                this.exploreEventsService.getEventsList(
                    'trending',
                    this.eventType,
                    this.publishType,
                    this.eventDay,
                    this.searchQueryEvents
                ),
                this.exploreEventsService.getEventsList(
                    'recommended',
                    this.eventType,
                    this.publishType,
                    this.eventDay,
                    this.searchQueryEvents
                ),
                this.exploreEventsService.getEventsList(
                    'ongoing',
                    this.eventType,
                    this.publishType,
                    this.eventDay,
                    this.searchQueryEvents
                ),
                this.exploreEventsService.getEventsList(
                    'upcoming',
                    this.eventType,
                    this.publishType,
                    this.eventDay,
                    this.searchQueryEvents
                ),
                this.exploreEventsService.getEventsList(
                    'replay',
                    this.eventType,
                    this.publishType,
                    this.eventDay,
                    this.searchQueryEvents
                )
            ]).subscribe((responseList) => {
                this.sendData(responseList);
            });
        } else {
            this.isLoading = true;
            this.exploreEventsService
                .getEventsList('favourites', this.eventType, this.publishType, this.eventDay, this.searchQueryEvents)
                .subscribe((data) => {
                    this.favouriteEventsList = data.resultMeetingDetails;
                    this.isLoading = false;
                });
        }
    }
    sendData(list) {
        this.trendingEventsLength = list[0].resultMeetingDetails.length;
        this.recommendedEventsLength = list[1].resultMeetingDetails.length;
        this.ongoingEventsLength = list[2].resultMeetingDetails.length;
        this.upcomingEventsLength = list[3].resultMeetingDetails.length;
        //send data to explore events service only if current route is '/explore'
        if (this.currentRoute !== '/dashboard') {
            this.replayEventsLength = list[4].resultMeetingDetails.length;
            this.exploreEventsService.trendingObject.next(list[0]);
            this.exploreEventsService.recommendedObject.next(list[1]);
            this.exploreEventsService.ongoingObject.next(list[2]);
            this.exploreEventsService.upcomingObject.next(list[3]);
            this.exploreEventsService.replayObject.next(list[4]);
        }
        this.isLoading = false;
        if (this.currentRoute == '/dashboard') {
            this.dashboardData = [
                ...list[2].resultMeetingDetails,
                ...list[3].resultMeetingDetails,
                ...list[1].resultMeetingDetails,
                ...list[0].resultMeetingDetails
            ];
        }
    }
    searchQueryHandler(event) {
        this.searchModelChanged.next(event);
    }

    closeSearchQueryEvents() {
        this.searchQueryEvents = '';
        this.searchModelChanged.next('');
    }

    mobileFilter(name, type, gtmLable = null) {
        if (gtmLable) {
            let eventData = EXPLORE_EVENTS.EXPLORE_FILTER1;
            eventData.clicked_text = gtmLable;
            this.changeCurrentPageForGA4(eventData);
        }
        if (type == 'publishType') this.publishType = name;
        else this.eventDay = name;
        this.callListApi();
        //API CALL
    }

    favouritesFilter() {
        let eventData = EXPLORE_EVENTS.EXPLORE_FILTER2;
        eventData.clicked_text = 'filter_my_favourites';
        this.changeCurrentPageForGA4(eventData);
        this.allEvents = false;
        if (!this.isAuthenticated) {
            this.router.navigate(['login', 'v1']);
            return;
        }
        if (this.favourites) {
            this.allEventsItems('all');
        } else {
            this.favourites = true;
            this.eventType = 'all';
            this.callListApi();
        }
        //API CALL
    }

    markFavourite(event) {
        this.isFavouritesLoading = true;
        if (event.post) {
            this.exploreEventsService.postFavourite(event.id).subscribe((data: any) => {
                this.exploreEventsService.favouriteDetails.next({
                    ...data,
                    index: event.index,
                    sliderName: event.sliderName
                });
                this.isFavouritesLoading = false;
            });
        } else {
            this.exploreEventsService.deleteFavourite(event.id).subscribe(() => {
                this.isFavouritesLoading = false;
                if (this.favourites) this.callListApi();
            });
        }
    }

    clickOutside(name) {
        if (this.isMobile) return;
        if (name == 'filter') {
            this.filters = false;
        }
        if (name == 'allEvents') {
            this.allEvents = false;
        }
    }

    toggleFilter(e) {
        e.stopPropagation();
        if (this.allEvents) this.allEvents = false;
        this.filters = !this.filters;
        if (this.filters) {
            this.eventDayState = this.eventDay;
            this.publishTypeState = this.publishType;
            this.FilterTrigger.emit(this.eventTypeFiltertRef);
        }
    }

    closeAllEvents() {
        this.allEvents = false;
    }

    toggleAllEvents() {
        if (this.filters) this.filters = false;
        this.allEvents = !this.allEvents;
        if (this.utilService.isMobileView) {
            this.DropDownFooter.emit(this.eventTypeListRef);
        } else {
            this.DropDownFooter.emit(null);
        }
    }

    filterItems(name, type, gtmLable = null) {
        if (type == 'eventDay') {
            this.eventDayState = this.eventDayState == name ? 'all' : name;
            this.filergtmLabel.eventDay = gtmLable;
        } else {
            this.publishTypeState = this.publishTypeState == name ? 'all' : name;
            this.filergtmLabel.eventType = gtmLable;
        }
    }

    removeAppliedFilter(type) {
        if (type === 'Organisation' || type === 'Public') {
            this.publishType = 'all';
            this.filergtmLabel.eventDay = '';
        } else {
            this.eventDay = 'all';
            this.filergtmLabel.eventType = '';
        }
        this.filters = false;
        this.callListApi();
        this.filterOptions = this.filterOptions.filter((value) => value !== type);
        //API CALL
    }

    applyFilters() {
        if (this.eventDayState == 'all' && this.publishTypeState == 'all') return;
        this.eventDay = this.eventDayState;
        this.publishType = this.publishTypeState;
        this.filterEventType();
        if (this.filergtmLabel.eventDay && this.eventDayState != 'all') {
            let eventData = EXPLORE_EVENTS.EXPLORE_FILTER1;
            eventData.clicked_text = this.filergtmLabel.eventDay;
            this.changeCurrentPageForGA4(eventData);
        }
        if (this.filergtmLabel.eventType && this.publishTypeState != 'all') {
            let eventData = EXPLORE_EVENTS.EXPLORE_FILTER1;
            eventData.clicked_text = this.filergtmLabel.eventType;
            this.changeCurrentPageForGA4(eventData);
        }
        this.filters = false;
        this.callListApi();
        //API CALL
    }

    filterEventType() {
        this.filterOptionsObject = this.exploreEventsService.getFilerObject() || { publishType: '', eventDay: '' };
        this.resetFilterEvent(this.publishType, 'publishType');
        this.resetFilterEvent(this.eventDay, 'eventDay');
        this.exploreEventsService.setFilterObject(this.filterOptionsObject);
        this.filterOptions = Object.values(this.filterOptionsObject);
    }

    resetFilterEvent(inputValue, inputKey) {
        if (inputValue !== 'all') {
            if (!Object.values(this.filterOptionsObject).includes(inputValue)) {
                delete this.filterOptionsObject[inputKey];
                this.filterOptionsObject = { ...this.filterOptionsObject, [inputKey]: inputValue };
            }
        } else {
            delete this.filterOptionsObject[inputKey];
        }
    }

    resetFilters() {
        this.publishType = 'all';
        this.filergtmLabel.eventDay = '';
        this.eventDay = 'all';
        this.filergtmLabel.eventType = '';
        this.filters = false;
        // this.callListApi();
    }

    allEventsItems(name, gtmLable = null) {
        if (gtmLable) {
            let eventData = EXPLORE_EVENTS.EXPLORE_FILTER2;
            eventData.clicked_text = gtmLable;
            this.changeCurrentPageForGA4(eventData);
        }
        this.allEvents = false;
        this.favourites = false;
        this.eventType = this.eventType == name ? 'all' : name;
        //API CALL
        this.callListApi();
    }

    getEvents(data) {
        this.exploreEventsService
            .getEventsList(
                data.name,
                this.eventType,
                this.publishType,
                this.eventDay,
                this.searchQueryEvents,
                data.offset,
                9
            )
            .subscribe((res) => {
                res.add = true;
                this.exploreEventsService[data.name + 'Object'].next(res);
            });
    }

    viewAllEvents(name) {
        // check if name is public then redirect to explore events
        if (name === 'all') {
            this.router.navigate(['/dashboard/explore']);
            return;
        }
        this.exploreEventsService
            .getEventsList(name, this.eventType, this.publishType, this.eventDay, this.searchQueryEvents, 0, 9)
            .subscribe((data) => {
                this.exploreEventsService[name + 'Object'].next(data);
            });
    }

    changeCurrentPageForGA4(event) {
        this.googleTagManager.pushGoogleTagData(Object.assign(event, { current_page: this.currentPage }));
    }
    eventClicked(event, i) {
        window.open(event.link);
    }

    createEvent($event) {
        // this.router.navigate(['/dashboard/createVirtual']);
        this.router.navigate(['/dashboard'], {
            relativeTo: this.activatedRoute,
            queryParams: { create: true }
        });
    }
    createPreRecord($event) {
        this.router.navigate(['/dashboard'], {
            relativeTo: this.activatedRoute,
            queryParams: { prerecorded: true }
        });
    }

    // handleActionMenuClick(event, meeting) {
    //     const data = event?.value || event?.option?.value;
    //     const func = this[data];
    //     if (event?.addToCal) {
    //         this.currentMeeting = meeting;
    //         this.showCalendar = !this.showCalendar;
    //     }
    // }
    cardEntered(event: KeyboardEvent) {
        if (event.key === 'enter') this.cardContent = true;
    }
    handleEnterEvent() {
        console.log('Enter key pressed!');
    }
    /* openSelect() {
        this.selectElementRef.nativeElement.focus();
        this.selectElementRef.nativeElement.click();
      } */
}

import { IncomingCallPopupComponent } from './incoming-call-popup/incoming-call-popup.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { JoinMeetingComponent } from './join-meeting/join-meeting.component';
import { JoinCallComponent } from './join-call/join-call.component';
import { HostMeetingComponent } from './host-meeting/host-meeting.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { GuestFormComponent } from './guest-form/guest-form.component';
import { TncComponent } from './tnc/tnc.component';
import { RecordingPlayerComponent } from './recording-player/recording-player.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AlreadyVerifiedComponent } from './already-verified/already-verified.component';
import { DownloadsPageComponent } from './downloads-page/downloads-page.component';
import { AddToJiomeetComponent } from './add-to-jiomeet/add-to-jiomeet.component';
import { DownloadRecordingsComponent } from './download-recordings/download-recordings.component';
import { RestrictedMeetingJoinComponent } from './restricted-meeting-join/restricted-meeting-join.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { GetClientComponent } from './get-client/get-client.component';
import { ActivateComponent } from './activate/activate.component';
import { OnboardingV1Component } from './onboarding-v1/onboarding-v1.component';
import { ForgotPasswordV1Component } from './forgot-password-v1/forgot-password-v1.component';
import { ResetPasswordV1Component } from './reset-password-v1/reset-password-v1.component';
import { VerificationComponent } from './verification/verification.component';

import * as fromLandingComponents from './landing-page';

import { LandingPageComponent } from './landing-page/landing-page.component';
import { SolutionsComponent } from './pages/solutions/solutions.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { EventSessionsComponent } from './event-sessions/event-sessions.component';
import { PlayerComponent } from './player/player.component';
import { FaqsComponent } from './pages/FAQ/faqs/faqs.component';
import { HomePageEventComponent } from './pages/home-page-event/home-page-event.component';
import { ExternalLoginComponent } from './external-login/external-login.component';
import { ScreenCaptureSelectionComponent } from './screen-capture-selection/screen-capture-selection.component';

export * from './forgot-password/forgot-password.component';
export * from './login/login.component';
export * from './change-password/change-password.component';
export * from './sign-up/sign-up.component';
export * from './join-meeting/join-meeting.component';
export * from './join-call/join-call.component';
export * from './host-meeting/host-meeting.component';
export * from './reset-password/reset-password.component';
export * from './guest-form/guest-form.component';
export * from './tnc/tnc.component';
export * from './privacy-policy/privacy-policy.component';
export * from './already-verified/already-verified.component';
export * from './downloads-page/downloads-page.component';
export * from './add-to-jiomeet/add-to-jiomeet.component';
export * from './download-recordings/download-recordings.component';
export * from './restricted-meeting-join/restricted-meeting-join.component';
export * from './onboarding/onboarding.component';
export * from './incoming-call-popup/incoming-call-popup.component';
export * from './get-client/get-client.component';
export * from './landing-page/landing-page.component';
export * from './pages/solutions/solutions.component';
export * from './pages/pricing/pricing.component';
export * from './pages/pricing-o/pricing-o.component';
export * from './pages/FAQ/faqs/faqs.component';
export * from './pages/support/support.component';
export * from './pages/contact/contact.component';
export * from './pages/contact-v2/contact-v2.component';
export * from './pages/virtual-events/virtual-events.component';
export * from './pages/webinars/webinars.component';
export * from './pages/whiteglove-service/whiteglove-service.component';
export * from './pages/customer/customer.component';
export * from './pages/coming-soon/coming-soon.component';
export * from './landing-page/landing-pageversion2/landing-pageversion2.component';
export * from './landing-page/landing-pageversion-o/landing-pageversion-o.component';
export * from './activate/activate.component';
export * from './forgot-password-v1/forgot-password-v1.component';
export * from './reset-password-v1/reset-password-v1.component';
export * from './onboarding-v1/onboarding-v1.component';
export * from './verification/verification.component';
export * from './event-sessions/event-sessions.component';
export * from './player/player.component';
export * from './recording-player/recording-player.component';
export * from './pages/home-page-event/home-page-event.component';
export * from './external-login/external-login.component';
export * from './screen-capture-selection/screen-capture-selection.component';

export const components = [
    RecordingPlayerComponent,
    ForgotPasswordComponent,
    LoginComponent,
    ChangePasswordComponent,
    SignUpComponent,
    JoinMeetingComponent,
    JoinCallComponent,
    HostMeetingComponent,
    ResetPasswordComponent,
    GuestFormComponent,
    TncComponent,
    PrivacyPolicyComponent,
    AlreadyVerifiedComponent,
    DownloadsPageComponent,
    AddToJiomeetComponent,
    DownloadRecordingsComponent,
    RestrictedMeetingJoinComponent,
    OnboardingComponent,
    IncomingCallPopupComponent,
    GetClientComponent,
    LandingPageComponent,
    SolutionsComponent,
    FaqsComponent,
    ActivateComponent,
    OnboardingV1Component,
    ForgotPasswordV1Component,
    ResetPasswordV1Component,
    VerificationComponent,
    HomePageEventComponent,
    ExternalLoginComponent,
    ScreenCaptureSelectionComponent,
    // EventSessionsComponent,
    PlayerComponent,
    ...fromLandingComponents.components
];

import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import * as audioBufferToWav from 'audiobuffer-to-wav';
import { environment } from 'src/environments/environment';
import { AppLoggerService } from './app-logger.service';
import VAD from '../../webinar-attendee/services/vad';

@Injectable({
    providedIn: 'root'
})
export class JmTranslateService {
    TRANSLATE_DOMAIN = 'https://translate.jio/';
    enableJmTranslateService: boolean = false;
    mediaRecorder: any;
    wasEnabled = false;
    selectedLanguage: 'Hindi' | 'English' | 'Telugu' | 'Tamil' | 'Gujarati' | 'Marathi' = 'Hindi';
    captionText = '';
    packetSendInterval;
    audioChunks = [];
    ambientNoiseLevel = null;
    audioStream: MediaStream;
    jmAudioTrack: MediaStreamTrack;
    vadInstance;
    sourceTrack: any;

    constructor(private restService: RestService, private appLoggerService: AppLoggerService) {
        if (environment.BASE_URL.includes('localhost')) {
            this.TRANSLATE_DOMAIN = '';
        }
    }

    directTranslate(payload) {
        return this.restService.post(`${this.TRANSLATE_DOMAIN}translator/direct-translate`, payload);
    }

    translatorSTT(payload) {
        return this.restService.post(`${this.TRANSLATE_DOMAIN}translator/stt`, payload);
    }

    translatorTTS(payload) {
        return this.restService.post(`${this.TRANSLATE_DOMAIN}translator/tts`, payload);
    }
    isTranslateUrl(url: string) {
        return url.indexOf('translator') > -1;
    }

    async trimSilence(audioBuffer) {
        const audioData = audioBuffer.getChannelData(0);
        const silenceThreshold = 0.01;
        let endIndex = audioData.length - 1;
        for (let i = audioData.length - 1; i >= 0; i--) {
            if (Math.abs(audioData[i]) > silenceThreshold) {
                endIndex = i;
                break;
            }
        }
        const duration = endIndex / audioBuffer.sampleRate;
        const audioContext = new AudioContext();
        const newBuffer = audioContext.createBuffer(
            audioBuffer.numberOfChannels,
            Math.ceil(duration * audioBuffer.sampleRate),
            audioBuffer.sampleRate
        );
        for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
            const newData = audioBuffer
                .getChannelData(channel)
                .subarray(0, Math.ceil(duration * audioBuffer.sampleRate));
            newBuffer.copyToChannel(newData, channel);
        }
        return newBuffer;
    }
    async convertTo44KAudioData(audioData, sourceSampleRate, targetSampleRate) {
        const audioContext = new OfflineAudioContext({
            numberOfChannels: audioData.numberOfChannels,
            length: audioData.length,
            sampleRate: targetSampleRate
        });
        const audioBuffer = audioContext.createBuffer(audioData.numberOfChannels, audioData.length, sourceSampleRate);
        audioBuffer.copyToChannel(audioData.getChannelData(0), 0);
        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContext.destination);
        source.start(0);
        const renderedBuffer = await audioContext.startRendering();
        return renderedBuffer;
    }
    wavBlobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader: any = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                let base64data = reader.result;
                if (base64data.split(',').length > 1) {
                    base64data = base64data.split(',')[1];
                    resolve(base64data);
                } else {
                    console.log('Error while splitting base64', base64data);
                }
            };
            reader.onerror = function (error) {
                reject(error);
            };
        });
    }

    stopListening() {
        if (!this.wasEnabled) {
            return;
        }
        this.audioStream = null;
        this.sourceTrack = null;
        this.jmAudioTrack = null;
        this.mediaRecorder.stop();
        if (this.packetSendInterval) {
            clearInterval(this.packetSendInterval);
        }
        this.wasEnabled = false;
    }

    detectVoiceVad() {
        let audioContext = new AudioContext();
        let source = audioContext.createMediaStreamSource(this.audioStream);
        let options = {
            source: source
        };

        // Create VAD
        this.vadInstance = new VAD(options);
        this.vadInstance.options.voiceStater.on('VOICE_START', () => {
            this.mediaRecorder.start();
            this.appLoggerService.log('Received Voice start');
        });
        this.vadInstance.options.voiceStater.on('VOICE_END', (event) => {
            this.appLoggerService.log('Received Voice end');
            this.mediaRecorder.stop();
        });
    }

    async detectVolumeDrops() {
        try {
            this.detectVoiceVad();
        } catch (err) {
            this.appLoggerService.log('Error occured when tried to detect volume drops');
        }
    }

    startTranslating(jmAudioTrack: any) {
        if (this.jmAudioTrack && this.audioStream) {
            this.appLoggerService.log('Already translating a track');
            return;
        }
        this.sourceTrack = jmAudioTrack;
        this.jmAudioTrack = jmAudioTrack.getMediaStreamTrack();
        this.audioStream = new MediaStream([this.jmAudioTrack]);
        this.detectVolumeDrops();
        this.mediaRecorder = new MediaRecorder(this.audioStream);
        this.mediaRecorder.ondataavailable = (event) => {
            console.log('jm audio chunk pushing', event);
            this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = () => {
            console.log('Media recorder stopped');
            this.convertAudioChunks(this.audioChunks);
        };
        this.wasEnabled = true;
        // this.mediaRecorder.start();
        // this.sendDataInPackets();
    }

    sendDataInPackets() {
        this.packetSendInterval = setInterval(() => {
            this.wasEnabled = true;
            this.mediaRecorder.stop();
            this.mediaRecorder.start();
        }, 5000);
    }

    convertAudioChunks(audioChunkData) {
        if (audioChunkData.length > 0) {
            console.log('jm audio blob creating', audioChunkData);
            const audioBlob = new Blob(audioChunkData, { type: 'audio/webm' });
            this.audioChunks = [];
            console.log('jm audio blob', audioBlob);
            this.convertToWavAndSend(audioBlob);
        }
    }

    convertToWavAndSend(audioBlob: Blob) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            const arrayBuffer = fileReader.result as ArrayBuffer;
            const audioContext = new AudioContext();
            audioContext
                .decodeAudioData(arrayBuffer)
                .then(async (audioBuffer) => {
                    // Converting to 16000Hz if it crosses 16000Hz
                    console.log('Before Convert Sample Rate:', audioBuffer.sampleRate);
                    if (audioBuffer?.sampleRate > 16000) {
                        audioBuffer = await this.convertTo44KAudioData(audioBuffer, audioBuffer.sampleRate, 16000);
                        audioBuffer = await this.trimSilence(audioBuffer);
                    }
                    console.log('After convert Sample Rate:', audioBuffer?.sampleRate);

                    const wavBuffer = audioBufferToWav.default(audioBuffer);
                    const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });
                    this.wavBlobToBase64(wavBlob)
                        .then((base64EncodedAudio) => {
                            const payload = {
                                platform: 'google',
                                config: {
                                    sampleRateHertz: 48000,
                                    encoding: 'LINEAR16',
                                    language: 'English'
                                },
                                audio: { content: base64EncodedAudio }
                            };
                            this.translatorSTT(payload).subscribe(
                                (data: any) => {
                                    console.log('STT - Translate data: ', data);
                                    if (data?.status === 'success') {
                                        console.log(data?.recognized_text);
                                        const text = data?.recognized_text;

                                        this.initiateTTT(data?.recognized_text);
                                    } else {
                                        console.error('Audio unclear. Please try again');
                                    }
                                },
                                (error) => {
                                    console.error('STT - Error fetching translation:', error);
                                }
                            );
                        })
                        .catch((error) => {
                            console.error('Error converting audio to Base64:', error);
                        });
                })
                .catch((error) => {
                    console.error('Error decoding audio:', error);
                });
        };
        fileReader.readAsArrayBuffer(audioBlob);
    }

    initiateTTT(text) {
        if (text == '' || text == null) {
            return;
        }
        const payload = {
            platform: 'google',
            q: text,
            source_language: 'English',
            target_language: this.selectedLanguage
        };
        this.directTranslate(payload).subscribe(
            (data: any) => {
                console.log('Translate data: ', data);
                if (data?.status === 'success') {
                    this.captionText = data?.translated_text;
                    this.playTrack('destination', data?.translated_text);
                    // if (from === 'source') {
                    //     this.destinationText = data?.translated_text;
                    // } else if (from === 'destination') {
                    //     this.sourceText = data?.translated_text;
                    // }
                    // this.playTrack(from === 'source' ? 'destination' : 'source');
                } else {
                    console.log('Audio unclear. Please try again');
                }
            },
            (err) => {
                console.log('Unable to translate. Please try again');
            }
        );
    }

    playTrack(from, text) {
        //this.stopTrack();

        const payload = {
            platform: 'google',
            input: {
                text: text
            },
            audioConfig: {
                encoding: 'LINEAR16'
            },
            language: this.selectedLanguage,
            gender: 'male'
        };
        this.translatorTTS(payload).subscribe(
            (data: any) => {
                console.log('TTS - Base64 data from API: ', data);
                const audio = new Audio('data:audio/wav;base64,' + data?.audioContent);
                // const audioTrack = new Audio('data:audio/wav;base64,' + data?.audioContent);
                audio.play();
                audio.addEventListener(
                    'ended',
                    function (e) {
                        if (from === 'source') {
                            this.sourcePlaying = false;
                        } else if (from === 'destination') {
                            this.destinationPlaying = false;
                        }
                    }.bind(this),
                    false
                );
                if (from === 'source') {
                } else if (from === 'destination') {
                }
            },
            (err) => {}
        );
    }

    toggleRemoteAudio(mute: boolean) {
        if (!this.sourceTrack) {
            this.appLoggerService.log('No Remote Audio Track Reference');
            return;
        }
        try {
            this.sourceTrack.setVolume(mute ? 0 : 1);
        } catch (err) {
            this.appLoggerService.log('ToggleRemoteAudio: Error occured ', err);
        }
    }
}

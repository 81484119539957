import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EXPLORE_EVENTS } from 'src/app/constants/gtm-events';
import {
    AppService,
    AuthService,
    DesktopAppService,
    EventData,
    EventEmitterService,
    MeetingService,
    UserService,
    UtilService
} from 'src/app/core';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { DASHBOARD } from '../../../constants/gtm-events';
import { EVENT_SETTINGS } from '../../../constants/gtm-events';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { EventViewSection } from 'src/app/constants/webinar-enum';
import { APP_EVENTS } from 'src/app/constants';
import { isBefore, addMinutes, isFuture, parseISO } from 'date-fns';

@Component({
    selector: 'app-event-card',
    templateUrl: './event-card.component.html',
    styleUrls: ['./event-card.component.scss'],
    providers: [ConfirmationService],
    encapsulation: ViewEncapsulation.None
})
export class EventCardComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly EventViewSection = EventViewSection;
    @Input() dashboard;
    @Input() previousWebinar;
    @Input() eventinfo;
    @Input() id;
    @Input() ongoingCall = false;
    @Input() actionMenus: any[] = [];
    @Input() rightMenuOptions: any[] = [];
    @Input() enableEventsReplay = true;
    @Input() bottomMenuOptions: any[] = [];
    @Input() eventViewSection: string = '';
    @Input() hasCalIcon = false;
    @Input() hasFavIcon = false;
    @Input() sliderName;
    isLandscape: boolean = false;
    favourites;
    @Output() favouriteEvent: EventEmitter<any> = new EventEmitter();
    @Output() eventClick: EventEmitter<any> = new EventEmitter();
    @Output() edit: EventEmitter<any> = new EventEmitter();
    @Output() delete: EventEmitter<any> = new EventEmitter();
    @Output() copyInvite: EventEmitter<any> = new EventEmitter();
    @Output() actionMenuClick: EventEmitter<any> = new EventEmitter();
    @ViewChild('markdown') markdown;
    @ViewChild('op') reportAnalyticsOverlayPanel;
    eventCardImageUrl;
    isEventsReplayEnabled;
    defaultimageurl;
    cardLink;
    showCalendar = false;
    markdowntext;
    tileBgImageLoaded: boolean = false;
    pastEvent = false;
    hash;
    currentPage: string = 'explore_events_page';
    currentUser;
    isCoHost;
    isAdHocCoHost;
    isHighlighted = false;
    useOldFlow: boolean = true;
    showDeleteModal: boolean = false;
    showDeleteSeriesModal: boolean = false;
    showConfirmDeleteModal: boolean = false;
    confirmDeleteMsg = '';
    deleteInSeries: 'current' | 'all' = 'current';
    showMeetingSharePopup: boolean = false;
    isPrerecorded: boolean = false;
    selectedOption: any;
    activeDropDown: string = '';
    favouriteIcon = 'add_to_favourite';
    addToCalIcon = 'add_to_cal';
    isMobile = false;
    showDate: boolean = true;
    reportAnalyticsOverlayPanelOpen: boolean = false;
    isOngoing: boolean = false;

    constructor(
        private renderer: Renderer2,
        private authservice: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private googleTagManager: GoogleTagMangerService,
        public utilService: UtilService,
        private userService: UserService,
        private webinarService: WebinarService,
        private appService: AppService,
        private confirmationService: ConfirmationService,
        private translateService: TranslateService,
        private eventEmitterService: EventEmitterService,
        private desktopAppService: DesktopAppService
    ) {
        this.activatedRoute.data.subscribe((route) => {
            this.dashboard =
                this.activatedRoute['_routerState'].snapshot.url.split('?')[0].split('/')[2] === 'my-events' ||
                this.dashboard;
        });
    }

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser() || this.utilService.isIpad();
        this.markdowntext = this.eventinfo.topicMarkdown ? this.sanitizeText(this.eventinfo.topicMarkdown.text) : '';
        this.defaultimageurl = `assets/img/jioconf/welcome-thumbnail-images/${this.eventinfo.jioConf.eventType}.png`;
        const isThumbnailIncludesCategory = this.eventinfo?.thumbnailLowRes?.includes('/events-category-thumbnails/');
        if (this.eventinfo?.thumbnailLowRes && isThumbnailIncludesCategory) {
            this.eventCardImageUrl = this.eventinfo?.welcomeThumbnailUrl || this.defaultimageurl;
        } else {
            this.eventCardImageUrl =
                this.eventinfo?.thumbnailLowRes || this.eventinfo?.welcomeThumbnailUrl || this.defaultimageurl;
        }
        this.favourites = this.eventinfo.favouriteId ? true : false;
        this.currentUser = this.userService.getUserSync();
        this.cardLink = this.eventinfo.webinar.registration.enabled
            ? this.eventinfo.webinar.registration.url
            : this.eventinfo.webinar.activation.url;
        this.checkIfPastEvent();
        if (this.pastEvent && (this.sliderName == 'recommended' || this.sliderName == 'trending')) {
            this.showDate = false;
        }
        if (this.pastEvent && this.sliderName != 'replay') {
            if (this.eventinfo.recordings) {
                this.cardLink = this.router.serializeUrl(
                    this.router.createUrlTree(['media-recordings', this.eventinfo._id])
                );
            }
        }
        if (this.dashboard) {
            this.currentPage = 'dashboard_explore_events';
        }
        this.isCoHost = this.webinarService.checkForCoHost(this.eventinfo?.participants || [], this.currentUser);
        this.isAdHocCoHost = this.webinarService.checkForAdHocCoHost(
            this.eventinfo?.participants || [],
            this.currentUser
        );
        this.isPrerecorded = this.eventinfo?.webinar?.preRecordedVideo?.enabled
            ? this.eventinfo?.webinar?.preRecordedVideo?.enabled
            : false;
        if (this.isAdHocCoHost) {
            this.isCoHost = false;
        }

        this.subscribeToEventEmitter();

        // build watch replay menu if event view section is past webinars
        this.isEventsReplayEnabled =
            this.appService.getConfigVariable('enableEventsReplayForAll') ||
            this.currentUser.webinarBetaAccess?.enableEventsReplay;
        if (this.eventViewSection === EventViewSection.PAST && this.isEventsReplayEnabled) {
            this.prepareWatchReplayMenus();
        }
        this.isOngoing = this.eventViewSection === EventViewSection.PAST || this.eventinfo?.ongoing === true;

        this.utilService.startCountdown(this.eventinfo);
    }

    @HostListener('window:orientationchange', ['$event'])
    isLandScapeMode() {
        setTimeout(() => {
            this.isLandscape = window.matchMedia('(orientation: landscape)').matches;
        }, 300);
    }

    prepareWatchReplayMenus() {
        this.rightMenuOptions = [];
        if (!this.eventinfo?.webinar?.registration?.inviteOnly && this.eventinfo?.repeatOptions.repeat == 'none') {
            this.rightMenuOptions = [
                { label: 'Watch Replay', value: 'watchReplay' },
                { label: this.eventinfo?.isReplayEnabled ? 'Edit Replay' : 'Enable Replay', value: 'toggleReplayPopup' }
            ];
            if (this.eventinfo?.isReplayEnabled) {
                this.rightMenuOptions.push({ label: 'Disable Replay', value: 'disableReplay' });
            }
        }
    }

    subscribeToEventEmitter() {
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.MYEVENT_ACTION_MENU_CLICK) {
                if (event.data?.meetingId === this.eventinfo?._id) this.handleActionMenuClick(event.data?.event);
            } else if (event.type === APP_EVENTS.CLOSE_ADD_TO_CAL_CLICK) {
                this.showCalendar = false;
            }
        });
    }

    get allowStart() {
        return (
            (this.eventinfo?.userId || this.eventinfo?.hostInfo?._id) === this.currentUser?._id ||
            this.isCoHost ||
            this.isAdHocCoHost
        );
    }
    startMeeting(event, eventinfo, from = 'start') {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        if (this.utilService.isMobileBrowser()) {
            return;
        }

        // check if sessions is enabled for this then redirect to sessions list page
        // if (meeting?.webinar?.sessions?.enabled) {
        //     window.open(`${this.appService.getBaseUrl()}event/${meeting._id}/sessions`, '_blank');
        // } else
        let eventType = from == 'join' ? DASHBOARD.JOIN_EVENT : DASHBOARD.START_EVENT;
        if (this.useOldFlow) {
            this.googleTagManager.pushGoogleTagData(
                Object.assign({}, eventType, {
                    meeting_Id: this.eventinfo._id,
                    Category: this.eventinfo?.jioConf?.eventType,
                    expected_attendee_count: this.eventinfo?.jioConf?.expectedAttendeeCount,
                    tags: this.eventinfo?.jioConf?.tags
                })
            );
            if (!eventinfo.maskedHash) {
                if (this.desktopAppService.isDesktopApp) {
                    this.router.navigate(['join'], {
                        queryParams: {
                            meetingId: eventinfo.jiomeetId,
                            pwd: eventinfo.pin || eventinfo.roomPIN
                        }
                    });
                } else {
                    window.open(
                        `${this.appService.getBaseUrl()}join?meetingId=${eventinfo.jiomeetId}&pwd=${
                            eventinfo.pin || eventinfo.roomPIN
                        }`
                    );
                }
                return;
            }
            window.open(`${this.appService.getBaseUrl()}join?sh=${eventinfo.maskedHash}`);
        } else {
            this.googleTagManager.pushGoogleTagData(
                Object.assign({}, DASHBOARD.START_EVENT, {
                    meeting_Id: this.eventinfo._id,
                    Category: this.eventinfo?.jioConf?.eventType,
                    expected_attendee_count: this.eventinfo?.jioConf?.expectedAttendeeCount,
                    tags: this.eventinfo?.jioConf?.tags
                })
            );
            this.navigateToEvent(eventinfo.jiomeetId, eventinfo.pin || eventinfo.roomPIN, true);
        }
    }

    joinPreRecordedEvent() {
        const AttendeeUrl = this.eventinfo.webinar?.activation?.url || this.eventinfo.webinar?.registration?.url;
        window.open(AttendeeUrl, '_blank');
    }

    navigateToEvent(jiomeetId: String = null, roomPin: String = null, asSpeaker: boolean = false) {
        const AttendeeUrl = this.eventinfo.webinar?.activation?.url || this.eventinfo.webinar?.registration?.url;
        window.open(AttendeeUrl, '_blank');
    }
    editMeeting(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        this.googleTagManager.pushGoogleTagData(EVENT_SETTINGS.EDIT_EVENTS);
        this.edit.emit({ single: false });
        this.actionMenuClick.emit(this.selectedOption);
    }
    deleteMeeting(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        this.confirmDeleteMsg = this.translateService.instant('webinar-card.cancel_confirmation', {
            value: this.eventinfo.topic
        });
        if (this.eventinfo.repeatOptions?.repeat !== 'none') {
            this.showDeleteSeriesModal = true;
        } else {
            // this.confirmDelete();
            this.showConfirmDeleteModal = true;
        }
    }
    confirmDelete() {
        this.showDeleteSeriesModal = false;
        this.showConfirmDeleteModal = true;
    }

    processDelete() {
        this.delete.emit(this.deleteInSeries);
        this.selectedOption.data = this.deleteInSeries;
        this.actionMenuClick.emit(this.selectedOption);
    }

    toggleMeetingSharePopup(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        if (!this.showMeetingSharePopup) {
            this.googleTagManager.pushGoogleTagData(
                Object.assign({}, DASHBOARD.SHARE_EVENT, {
                    meeting_Id: this.eventinfo._id,
                    Category: this.eventinfo?.jioConf?.eventType,
                    expected_attendee_count: this.eventinfo?.jioConf?.expectedAttendeeCount,
                    tags: this.eventinfo?.jioConf?.tags
                })
            );
        }
        this.showMeetingSharePopup = !this.showMeetingSharePopup;
        this.actionMenuClick.emit(this.selectedOption);
    }
    copyInvitation(type) {
        this.copyInvite.emit(type);
        this.actionMenuClick.emit(this.selectedOption);
    }
    handleTileLoadingStatus(event) {
        this.tileBgImageLoaded = event.success && !event.loading;
    }
    previewEvent() {
        this.googleTagManager.pushGoogleTagData(
            Object.assign({}, DASHBOARD.PREVIEW_EVENT, {
                meeting_Id: this.eventinfo._id,
                Category: this.eventinfo?.jioConf?.eventType,
                expected_attendee_count: this.eventinfo?.jioConf?.expectedAttendeeCount,
                tags: this.eventinfo?.jioConf?.tags
            })
        );

        this.previewNavigate();
        this.actionMenuClick.emit(this.selectedOption);
    }
    previewNavigate() {
        if (this.isPrerecorded) {
            const previewUrl = `events/in-session/preview/${this.eventinfo._id}?isFromDashboard=true`;
            window.open(previewUrl, '_blank');
        } else {
            this.navigateToEvent();
        }
    }
    checkIfPastEvent() {
        let currentTime = new Date();
        let endTime = new Date(this.eventinfo.endTime);
        this.pastEvent = endTime < currentTime ? true : false;
    }

    ngAfterViewInit(): void {
        var qleditor = this.markdown?.nativeElement?.children?.[0];
        if (qleditor) {
            this.renderer.addClass(qleditor, 'ql-editor');
            if (!qleditor.children || qleditor.children.length === 0) {
                console.error('qleditor has no children');
            }
        }
    }

    sanitizeText(text) {
        return this.utilService.sanitizeAndConvertEncodedHTML(text);
    }

    addtocalender() {
        this.showCalendar = !this.showCalendar;
        if (this.showCalendar) {
            this.googleTagManager.pushGoogleTagData(
                Object.assign(EXPLORE_EVENTS.ADD_CALINDER, { current_page: this.currentPage })
            );
        }
        this.openAddToCalOptions();
    }
    dontShowCalendar() {
        this.showCalendar = false;
    }
    toggleFavourites() {
        if (!this.authservice.getIsAuthenticated()) {
            this.router.navigate(['login', 'v1']);
            return;
        }
        if (!this.favourites) {
            this.googleTagManager.pushGoogleTagData(
                Object.assign(EXPLORE_EVENTS.ADD_FAVORITES, { current_page: this.currentPage })
            );
            this.favouriteEvent.emit({ id: this.eventinfo._id, post: true });
            this.favourites = true;
        } else {
            this.favouriteEvent.emit({ id: this.eventinfo.favouriteId, post: false });
            this.favourites = false;
        }
    }

    handleActionMenuClick(event) {
        this.selectedOption = event.option;
        const selectedOption = this.selectedOption;
        if (this[selectedOption.value] && typeof this[selectedOption.value] === 'function') {
            this[selectedOption.value](event.event);
        } else {
            this.actionMenuClick.emit(selectedOption);
        }
        // this.edit.emit({ single: false });
    }

    gotoevent(event) {
        this.eventClick.emit({ name: this.markdown?.nativeElement?.firstChild?.textContent, link: this.cardLink });
        // window.open(this.cardLink);
    }

    openMyEventsMenuOptions(e) {
        e.preventDefault();
        e.stopPropagation();
        this.actionMenuClick.emit({ rightMenuOptionClick: true, menuOptions: this.rightMenuOptions });
        this.toggledotmenu();
    }

    openReportAnalytics(e) {
        if (this.reportAnalyticsOverlayPanelOpen) {
            this.reportAnalyticsOverlayPanel.toggle(e);
            this.reportAnalyticsOverlayPanelOpen = false;
            return;
        } else {
            // Simulate Outside click
            document.getElementById('switchTab_2').click();
            this.reportAnalyticsOverlayPanel.toggle(e);
            this.reportAnalyticsOverlayPanelOpen = true;
            this.actionMenuClick.emit({ reportAnalyticClick: true, eventId: this.eventinfo._id });
        }
    }

    toggledotmenu() {
        this.isHighlighted = !this.isHighlighted;
    }

    openAddToCalOptions() {
        this.eventEmitterService.emit({
            type: APP_EVENTS.OPEN_ADD_TO_CAL,
            data: { addToCal: true, meeting: this.eventinfo }
        });
    }

    isBefore(minutesToAddToCurrentTime = 0) {
        return isBefore(addMinutes(new Date(), minutesToAddToCurrentTime), new Date(this.eventinfo.startTime));
    }

    isMeetingInFuture() {
        return isFuture(parseISO(this.eventinfo?.startTime));
    }

    ngOnDestroy() {
        clearInterval(this.eventinfo?.intervalId);
    }

    toggleClose() {
        this.isHighlighted = false;
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, CheckForSpeakerEntryGuard, OnboardingGuard, NavigationGuard } from './core';

import * as fromComponents from './components';
import { CheckForCohostEntryGuard } from './core/guards/check-for-cohost-entry.guard';
import { ExploreEventsComponent } from './shared/components/explore-events/explore-events.component';
import { PlayerComponent } from './components';
import { GetUserDetailsComponent } from './get-user-details/get-user-details.component';
import { UserDetailsGuard } from './core/guards/user-details-guard.guard';

const routes: Routes = [
    {
        path: 'login',
        component: fromComponents.LoginComponent,
        data: { showHeader: false, showFooter: false },
        canActivate: []
    },
    {
        path: 'authenticateurl',
        component: fromComponents.DownloadRecordingsComponent,
        data: { showHeader: false, showFooter: false }
    },
    // {
    //   path: 'signup',
    //   component: fromComponents.SignUpComponent,
    //   data: { showHeader: false, showFooter: false },
    //   canActivate: []
    // },
    {
        path: 'event/:id/sessions',
        component: fromComponents.EventSessionsComponent,
        data: { showHeader: true, showFooter: false, handleRedirection: true }
    },
    {
        path: 'join',
        component: fromComponents.JoinCallComponent,
        data: { showHeader: true, showFooter: false, handleRedirection: true },
        canActivate: [CheckForSpeakerEntryGuard]
    },
    {
        path: 'shortener',
        component: fromComponents.DownloadsPageComponent,
        data: { showHeader: false, showFooter: false },
        canActivate: [CheckForSpeakerEntryGuard]
    },

    {
        path: 'meetingInvite/join/:hash',
        component: fromComponents.DownloadsPageComponent,
        data: { showHeader: true, showFooter: false },
        canActivate: [CheckForCohostEntryGuard]
    },

    {
        path: 'addtojiomeet',
        component: fromComponents.DownloadsPageComponent,
        data: { showHeader: true, showFooter: false, type: 'add', handleRedirection: true },
        canActivate: [AuthGuard]
    },
    {
        path: 'addtojioevents',
        component: fromComponents.DownloadsPageComponent,
        data: { showHeader: true, showFooter: false, type: 'add', handleRedirection: true },
        canActivate: [AuthGuard]
    },

    // {
    //   path: 'guest',
    //   component: fromComponents.GuestFormComponent,
    //   data: { showHeader: true, showFooter: true },
    //   canActivate: []
    // },

    {
        path: 'forgot-password',
        component: fromComponents.ForgotPasswordComponent,
        data: { showHeader: true, showFooter: false },
        canActivate: []
    },
    {
        path: 'forgot-password/v1',
        component: fromComponents.ForgotPasswordV1Component,
        data: { showHeader: false, showFooter: false },
        canActivate: []
    },

    {
        path: 'home2',
        component: fromComponents.LandingPageComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'home',
        component: fromComponents.LandingPageversion2Component,
        data: { showHeader: true, showFooter: false },
        canActivate: []
    },
    {
        path: 'eventview',
        component: fromComponents.HomePageEventComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'solutions',
        component: fromComponents.SolutionsComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'faqs',
        component: fromComponents.FaqsComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'pricing',
        component: fromComponents.PricingComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'explore-events',
        component: fromComponents.HomePageEventComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'media-recordings/:id',
        component: PlayerComponent,
        data: { showHeader: false, showFooter: false },
        canActivate: []
    },
    {
        // component for playing recording
        path: 'mediaplayer',
        component: fromComponents.RecordingPlayerComponent,
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'support',
        component: fromComponents.SupportComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'contactus',
        component: fromComponents.ContactComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'virtual-events',
        component: fromComponents.VirtualEventsComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'webinars',
        component: fromComponents.WebinarsComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'events',
        component: fromComponents.WebinarsComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'whiteglove',
        component: fromComponents.WhitegloveServiceComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'customer',
        component: fromComponents.CustomerComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'comingsoon',
        component: fromComponents.ComingSoonComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'conference',
        loadChildren: () => import('./conference/conference.module').then((m) => m.ConferenceModule),
        canActivate: []
    },
    {
        path: 'polls',
        loadChildren: () => import('./polls/polls.module').then((m) => m.PollsModule),
        canActivate: []
    },
    {
        path: 'onboarding/v1',
        component: fromComponents.OnboardingComponent,
        data: { showHeader: true, showFooter: false },
        canActivate: [AuthGuard]
    },
    {
        path: 'onboarding',
        component: fromComponents.OnboardingV1Component,
        data: { showHeader: false, showFooter: false },
        canActivate: [AuthGuard]
    },
    {
        path: 'reset-password',
        component: fromComponents.ResetPasswordV1Component,
        data: { showHeader: false, showFooter: false },
        canActivate: []
    },
    {
        path: 'get-user-details',
        component: GetUserDetailsComponent,
        data: { showHeader: false, showFooter: false },
        canActivate: [AuthGuard, UserDetailsGuard]
    },
    {
        path: 'terms-condition-business',
        component: fromComponents.TncComponent,
        data: { showHeader: true, showFooter: true }
    },
    {
        path: 'privacy-policy-business',
        component: fromComponents.PrivacyPolicyComponent,
        data: { showHeader: true, showFooter: true }
    },

    {
        path: 'attendee',
        loadChildren: () => import('./webinar-attendee/webinar-attendee.module').then((m) => m.WebinarAttendeeModule),
        data: { showHeader: false, showFooter: false },
        canActivate: [CheckForSpeakerEntryGuard]
    },
    {
        path: 'webinar',
        loadChildren: () => import('./webinar-attendee/webinar-attendee.module').then((m) => m.WebinarAttendeeModule),
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard, OnboardingGuard, NavigationGuard],
        data: { showHeader: true, handleRedirectionOnQueryParamCheck: true }
    },
    {
        path: 'precalltesting',
        loadChildren: () => import('./pre-call-testing/pre-call-testing.module').then((m) => m.PreCallTestingModule),
        canActivate: [],
        data: { showHeader: true }
    },
    {
        path: 'events',
        loadChildren: () =>
            import('./dashboard-webinars/dashboard-webinars.module').then((m) => m.DashboardWebinarModule),
        data: { showHeader: false, handleRedirection: true }
    },
    {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [AuthGuard],
        data: { showHeader: true }
    },
    {
        path: 'events-studio',
        loadChildren: () => import('./events-studio/events-studio.module').then((m) => m.EventsStudioModule),
        canActivate: [AuthGuard],
        data: { showHeader: true }
    },
    {
        path: 'studio',
        loadChildren: () => import('./marketplace/marketplace.module').then((m) => m.MarketplaceModule),
        canActivate: [AuthGuard],
        data: { showHeader: true }
    },
    {
        path: 'integrations',
        loadChildren: () => import('./integrations/integrations.module').then((m) => m.IntegrationsModule),
        data: { showHeader: false, showFooter: false }
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
    },
    {
        path: 'activate',
        component: fromComponents.ActivateComponent,
        data: { showHeader: false }
    },
    {
        path: 'verification',
        component: fromComponents.VerificationComponent,
        data: { showHeader: false }
    },
    {
        path: 'externallogin',
        component: fromComponents.ExternalLoginComponent,
        data: { showHeader: false }
    }
    // {
    //     path: '**',
    //     redirectTo: 'dashboard'
    // }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}

<div class="screen-capture-selection-container">
    <div class="m-3">{{ 'screen-share.Share Screen' | translate }}</div>
    <div class="m-1 content-font-size">{{ 'screen-share.Contents' | translate }}</div>
    <div class="screen-capture-selection-component full" [class.show]="!!isLoading" *ngIf="isLoading">
        <app-pre-loader [title]="'Loading...'"></app-pre-loader>
    </div>

    <div class="tabs">
        <span #type class="tab-type-wrapper">
            <div class="tab">
                <span
                    tabindex="0"
                    class="tab-label entire-window"
                    (keyup.enter)="setSelection(selectionType[0].id)"
                    (click)="setSelection(selectionType[0].id)"
                    [class.active]="selectionType[0]?.id === activeType.id"
                >
                    {{ selectionType[0].tkey | translate }}
                </span>
                <span
                    tabindex="0"
                    class="tab-label window"
                    (keyup.enter)="setSelection(selectionType[1].id)"
                    (click)="setSelection(selectionType[1].id)"
                    [class.active]="selectionType[1]?.id === activeType.id"
                >
                    {{ selectionType[1].tkey | translate }}
                </span>
            </div>
        </span>
    </div>

    <div class="tab-content">
        <div class="list" *ngIf="activeType.id === 0">
            <div class="streams-container">
                <ng-container *ngFor="let ws of windowStreams">
                    <div
                        class="capture-stream-tile"
                        [ngClass]="{ 'selected-stream': selectedStream?.source?.id === ws?.source?.id }"
                        *ngIf="ws.source.name.toLowerCase() !== 'entire screen'"
                        (click)="onStreamSelected(ws)"
                    >
                        <ng-container>
                            <video
                                autoplay
                                oncanplay="this.play()"
                                class="flex-grow-1 video-tile"
                                [srcObject]="ws.stream"
                                *ngIf="showStreamVidep"
                            ></video>
                            <img
                                *ngIf="!showStreamVidep"
                                class="flex-grow-1 image-tile"
                                id="{{ 'img_' + ws.source.id }}"
                                alt="screen-share"
                                [src]="ws.source.thumbnailUrl"
                            />
                            <div class="stream-title">{{ ws.source.name }}</div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="list" *ngIf="activeType.id === 1">
            <div class="streams-container_full_screen">
                <ng-container *ngFor="let ws of windowStreams">
                    <div
                        class="capture-stream-tile_full_screen"
                        [ngClass]="{ 'selected-stream': selectedStream?.source?.id === ws?.source?.id }"
                        *ngIf="ws.source.name.toLowerCase() === 'entire screen'"
                        (click)="onStreamSelected(ws)"
                    >
                        <ng-container>
                            <video
                                autoplay
                                oncanplay="this.play()"
                                class="flex-grow-1 video-tile"
                                [srcObject]="ws.stream"
                                *ngIf="showStreamVidep"
                            ></video>
                            <img
                                *ngIf="!showStreamVidep"
                                class="flex-grow-1 image-tile"
                                id="{{ 'img_' + ws.source.id }}"
                                alt="screen-share"
                                [src]="ws.source.thumbnailUrl"
                            />
                            <div class="stream-title">{{ 'screen-share.entire_screen' | translate }}</div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="d-flex ml-auto mt-auto">
        <button pButton type="button" class="m-2 p-button-secondary p-button-outlined cancel-button" (click)="cancel()">
            {{ 'screen-share.cancel' | translate }}
        </button>
        <button pButton type="button" class="m-2 share-button" [disabled]="!selectedStream" (click)="share()">
            {{ 'screen-share.share' | translate }}
        </button>
    </div>
</div>

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { start } from 'repl';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { AppService, AuthService, RestService, GuestAuthService, RtcService, UtilService } from 'src/app/core';
import * as _ from 'lodash';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { ToastrService } from 'ngx-toastr';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { AppErrorHandler } from 'src/app/core/interceptors/error.handler';

@Injectable({
    providedIn: 'root'
})
export class WebinarConferenceService {
    private isAuthenticated;
    reaction$ = new BehaviorSubject({ type: 'like', state: false });
    private headers: HttpHeaders = new HttpHeaders();
    public attendeesListPageOffset = 0;
    public attendeesListPageSize = 0;
    private isSessionEnabled = false;
    private isStudioEnabled = false;
    private sortOrderPortal = 1;
    currentWebinarUpdates: any;
    constructor(
        private restService: RestService,
        private appService: AppService,
        private authService: AuthService,
        public rtcService: RtcService,
        private webinarService: WebinarService,
        private appErrorHandler: AppErrorHandler,
        private toasterService: ToastrService,
        private utilService: UtilService
    ) {
        this.isAuthenticated = this.authService.getIsAuthenticated();
        this.attendeesListPageSize = this.appService.getConfigVariable('SPEAKER_SIDE_ATTENDEES_COUNT_LIMIT') || 1000;
    }

    startWebinarBroadcasting(webinarId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/startbroadcasting`,
            {}
        );
    }

    stopWebinarBroadcasting(webinarId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/stopbroadcasting`,
            {}
        );
    }
    pauseWebinarBroadcasting(webinarId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/pausebroadcasting`,
            {}
        );
    }
    resumeWebinarBroadcasting(webinarId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/resumebroadcasting`,
            {}
        );
    }

    getQuestionsForHost(webinarId, { offset = 0, limit }) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/qnas${
                !this.isAuthenticated ? '/guest' : ''
            }?offset=${offset}&limit=${limit}`
        );
    }

    rejectQuestion(webinarId, qId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/qnas/${qId}/reject${
                !this.isAuthenticated ? '/guest' : ''
            }`,
            {}
        );
    }
    answerQuestion(webinarId, qId, answerLive: boolean, answer = '') {
        const payload: any = {
            answer: answer,
            answerLive: answerLive
        };
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/qnas/${qId}/answer${
                !this.isAuthenticated ? '/guest' : ''
            }`,
            payload
        );
    }

    answerQuestionLive(webinarId, qId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/qnas/${qId}/answerlive${
                !this.isAuthenticated ? '/guest' : ''
            }`,
            {}
        );
    }

    answeredLiveDone(webinarId, qId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/qnas/${qId}/answerlivedone${
                !this.isAuthenticated ? '/guest' : ''
            }`,
            {}
        );
    }

    getCommentForHost(webinarId, { offset = 0, limit }) {
        return this.restService.get(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/${webinarId}/webinar/comments?offset=${offset}&limit=${limit}`
        );
    }

    clearQnAForSpeakers(webinarId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/clear/qnas`,
            {}
        );
    }

    setTimeLimitForQA(webinarId, qnaTimeLimit) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/qnas/timelimit`,
            { qnaTimeLimit }
        );
    }

    getQaUnansweredCount(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/qnas/unansweredCount`
        );
    }

    getTicker(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/ticker`
        );
    }

    toggleTicker(webinarId, enabled) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/ticker`,
            { enabled }
        );
    }

    postTicker(webinarId, { enabled = true, text = '', commentId = '', withUserName = true, messageType = '' }) {
        const payload: any = {
            ...{ enabled },
            ...(text && { text }),
            ...(commentId && { commentId }),
            ...{ withUserName },
            ...(messageType && { messageType })
        };
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/ticker`,
            payload
        );
    }

    removeTicker(webinarId, tickerId) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/ticker/${tickerId}`
        );
    }

    getStats(webinarId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/stats`);
    }

    startLivestreaming(webinarId, livestreamType) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/livestream/${livestreamType}/start`,
            {}
        );
    }

    stopLivestreaming(webinarId, livestreamType) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/livestream/${livestreamType}/stop`,
            {}
        );
    }

    updateLiveStream(webinarId, livestreamType, payload) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/livestream/${livestreamType}`,
            payload
        );
    }
    //&type=customlayoutvideo
    getMediaList(webinarId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/media?meetingid=${webinarId}`);
    }

    shareMedia(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/media/share`, payload);
    }

    videoUrl(videoUrl, meetingId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/media/external-urls?meetingid=${meetingId}`,
            {
                uri: videoUrl
                // duration: videoDuration
            }
        );
    }
    mediaplaySync(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/media/mediaplaysync`, payload);
    }
    uploadMediaFile(file, meetingId): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/media?meetingid=${meetingId}`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }
    deleteMediaFile(mediaId) {
        return this.restService.delete(`${this.appService.getEnvVariable('BASE_URL')}/media`, {
            body: { mediaId }
        });
    }
    getLikeDetails(meetingId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/likesdetails${
                !this.isAuthenticated ? '/guest' : ''
            }`
        );
    }

    setTimer(meetingId, { setTime = '00:00', initialSetTime, state = '', refresh = false, update = false }) {
        // state can be start,stop or pause
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/timer`,
            { setTime, initialSetTime, state, refresh, update }
        );
    }

    getPollingEventData(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/status/updates/host${
                !this.isAuthenticated ? '/guest' : ''
            }`
        );
    }

    getPollingEventDataFromPortal(webinarId, payload) {
        var requestUrl = `${this.appService.getEnvVariable(
            'BASE_URL'
        )}/portal/meeting/${webinarId}/webinar/status/hostupdates`;
        return this.restService.post(requestUrl, payload);
    }

    getPollingEventDataForEventControls(webinarId, payload) {
        var requestUrl = `${this.appService.getEnvVariable(
            'BASE_URL'
        )}/eventcontrols/meeting/${webinarId}/webinar/status/hostupdates`;
        return this.restService.post(requestUrl, payload);
    }

    toggleLikeStatus(webinarId, type) {
        return this.restService
            .post(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/like${
                    this.isAuthenticated ? '' : '/guest'
                }`,
                {
                    type: type
                },
                { headers: this.headers }
            )
            .pipe(tap(() => this.reaction$.next({ type, state: true })));
    }

    toggleUnlikeStatus(webinarId, type) {
        return this.restService
            .post(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/unlike${
                    this.isAuthenticated ? '' : '/guest'
                }`,
                {},
                { headers: this.headers }
            )
            .pipe(tap(() => this.reaction$.next({ type, state: false })));
    }

    getAllAttendees(meetingId, participated) {
        return this.restService.get(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/${meetingId}/webinar/attendees?participated=${participated}`
        );
    }

    getAllAttendeeParticipants(meetingId, fromAttendee, pageNumber, pageSize) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/attendees/participants${
                !this.isAuthenticated ? '/guest' : ''
            }?fromAttendee=${fromAttendee}&offset=${pageNumber}&limit=${pageSize}`
        );
    }

    getAutoJoinBotHash(meetingId, jiomeetId) {
        var requestUrl = `${this.appService.getEnvVariable('BASE_URL')}/eventcontrols/attendee/previewInfo`;
        return this.restService.post(requestUrl, { meetingId: meetingId, jiomeetId: jiomeetId });
    }

    getMeetingHistoryId(jiomeetId) {
        const requestUrl = `/api/eventcontrols/callhistoryinfo/get`;
        return this.restService.post(requestUrl, { jiomeetId: jiomeetId });
    }

    getAttendeesByIds(meetingId, fromAttendee, userIds) {
        const payload: any = {
            userIds: userIds
        };
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/attendees/byIds${
                !this.isAuthenticated ? '/guest' : ''
            }?fromAttendee=${fromAttendee}`,
            { payload }
        );
    }

    getAllAttendeesForGuest(meetingId, sortOrder, pageNumber, pageSize, keyword) {
        var requestUrl = `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/attendees/list${
            !this.isAuthenticated ? '/guest' : ''
        }?firstName=${sortOrder}&lastName=${sortOrder}&offset=${pageNumber}&limit=${pageSize}&participated=true&inWebinar=true`;
        if (!_.isEmpty(keyword)) {
            requestUrl = requestUrl + `&keyword=${keyword}`;
        }
        return this.restService.get(requestUrl);
    }

    getActiveAttendeesCount(meetingId) {
        var requestUrl = `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/attendees/list${
            !this.isAuthenticated ? '/guest' : ''
        }?participated=true&inWebinar=true`;
        return this.restService.get(requestUrl);
    }

    setSortKeys(key) {
        if (key === 'ztoa') {
            this.sortOrderPortal = -1;
        } else {
            this.sortOrderPortal = 1;
        }
    }

    getAttendeesFromPortal(meetingId, payload) {
        var requestUrl = `${this.appService.getEnvVariable('BASE_URL')}/portal/${meetingId}/attendeelist?firstName=${
            this.sortOrderPortal
        }&lastName=${this.sortOrderPortal}&participated=true&inWebinar=true`;
        return this.restService.post(requestUrl, payload);
    }

    getAttendeesForEventControls(meetingId, payload, sortOrder, offset, limit, keyword) {
        var requestUrl = `${this.appService.getEnvVariable(
            'BASE_URL'
        )}/eventcontrols/${meetingId}/attendeelist?firstName=${sortOrder}&lastName=${sortOrder}&offset=${offset}&limit=${limit}&participated=true&inWebinar=true`;
        if (!_.isEmpty(keyword)) {
            requestUrl = requestUrl + `&keyword=${keyword}`;
        }
        return this.restService.post(requestUrl, payload);
    }

    setSessionEnabled(sessionEnabled = false) {
        this.isSessionEnabled = sessionEnabled;
    }
    getSessionEnabled() {
        return this.isSessionEnabled;
    }

    setStudioEnabled(studioTemplateId) {
        this.isStudioEnabled = studioTemplateId ? true : false;
    }
    getStudioEnabled() {
        return this.isStudioEnabled;
    }

    getSessionsInfo(meetingId): Promise<{ sessions: []; speakers: [] }> {
        return new Promise((resolve, reject) => {
            this.webinarService.getWebinarSessionsInfo(meetingId, !this.authService.getAuthInfo()).subscribe(
                (res) => {
                    console.log(res, 'sessions list');
                    // const sessionsInfo = res?.sessions || [];
                    resolve(res);
                    // this.sessionSpeakers = res?.speakers || [];
                },
                (err) => {
                    console.log(err);
                    this.handleHttpErrorResponse(
                        err,
                        'Error while getting session info when trying to start broadcasting'
                    );
                    reject(err);
                }
            );
        });
    }

    handleHttpErrorResponse(err, errHeading = '', showErrorToast = true, updateLogger = true) {
        if (updateLogger) {
            this.appErrorHandler.handleError(errHeading, err);
        }
        if (showErrorToast) {
            this.toasterService.error(err?.error?.errors || err?.error?.message || 'Something went wrong');
        }
    }

    handleAttendeeListResponse(res) {
        const incomingAttendeeListCount = res.attendeesList.length;
        res.attendeesList = this.utilService.removeDuplicateArrayObj(res.attendeesList, 'userId');
        //check if there were any duplicate entries which was removed then handle count accordingly
        if (incomingAttendeeListCount !== res.attendeesList.length) {
            res.attendeesCount += res.attendeesList.length - incomingAttendeeListCount;
        }
    }
}

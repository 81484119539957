export const environment = {
    production: true,
    local: false,
    HOST_URL: 'https://rc.jioevents.com/',
    BASE_URL: 'https://rc.jioevents.com/api',
    CONF_URL: 'https://rc.jioevents.com/',
    JIO_CLOUD: {
        HOST: 'https://pp-apis.tejdrive.com',
        HOST_UPLOAD: 'https://pp-upload.tejdrive.com',
        API_KEY: '0b374bf6-26b8-4b5d-ba17-5167ce583853',
        APP_SECRET: 'YjczODIzYzctNTAyYS00YmJjLTkzYTUtYWMzMjVjYmM1MmQz'
    },
    DIGNOSTIC_API_KEY: 'ccb1eb70-095f-414f-8970-861282495dd4',
    DIGNOSTIC_API_SECRET: '9cf108a1070a7f48036c279166921feea3fc4781082458f54da091a5f81e',
    HAPTIC_CLIENT_ID: '6c6b13304bbfa790d48abf449984b853b6d377ba'
};

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AppService, AuthService, MeetingService, UserService, UtilService } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { isToday, parseISO } from 'date-fns';
import { ConvertToLocaleDatePipe } from 'src/app/shared';

@Component({
    selector: 'app-recording-player',
    templateUrl: './recording-player.component.html',
    styleUrls: ['./recording-player.component.scss'],
    providers: [ConvertToLocaleDatePipe]
})
export class RecordingPlayerComponent implements OnInit {
    @ViewChild('url_text') url_text: ElementRef;
    updateUrl: Subject<string> = new Subject();
    public urlUpdated = false;
    link: string;
    scriptsLoaded: boolean = false;
    isMobileOS: boolean = false;

    scripts: any = [
        { src: 'https://vjs.zencdn.net/7.3.0/video-js.css', loaded: false },
        { src: 'https://cdn.jsdelivr.net/npm/videojs-seek-buttons/dist/videojs-seek-buttons.css', loaded: false }
    ];

    recordingDetails: any = {};
    recordingDetailsAvailable: boolean = false;
    currentDay = new Date().getDay();

    constructor(
        private activatedRoute: ActivatedRoute,
        private appService: AppService,
        private meetingService: MeetingService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private loggerService: AppLoggerService,
        private router: Router,
        private utilService: UtilService,
        private convertToLocaleDatePipe: ConvertToLocaleDatePipe,
        private userService: UserService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        if (!this.authService.getIsAuthenticated()) {
            setTimeout(() => {
                this.toastrService.error(this.translateService.instant('tostrs.signin_to_continue'));
                this.router.navigate(['/login']);
            }, 100);
            return;
        }
        this.handleScriptLoading();
        this.isMobileOS = this.utilService.isMobileBrowser();
        this.activatedRoute.queryParams.subscribe((params) => {
            setTimeout(() => {
                this.meetingService.getVideoStream(params.hash).subscribe(
                    (res) => {
                        this.loggerService.log(res, 'getstream url response');

                        if (res?.success) {
                            this.meetingService.getRecordingDetailsByHash(params.hash).subscribe(
                                (details: any) => {
                                    this.recordingDetails = details;
                                    this.recordingDetailsAvailable = true;
                                    this.recordingDetails = details;
                                    this.link = res?.uri;
                                    this.urlUpdated = true;
                                    this.updateUrl.next(this.link);
                                },
                                (error) => {
                                    this.loggerService.log(error, 'error while fetching recording info');
                                    this.toastrService.info(error?.error?.errors);
                                    this.router.navigate(['/login'], { relativeTo: this.activatedRoute });
                                    return;
                                }
                            );
                        } else {
                            // redirect
                            const redirectUrl = `${this.appService.getEnvVariable(
                                'HOST_URL'
                            )}authenticateurl?redirectURL=${encodeURIComponent(window.location.href)}`;
                            window.location.href = redirectUrl;
                            //Need Desktop Routing
                            return;
                        }
                    },
                    (err) => {
                        if (err?.error?.customCode == 400) {
                            this.toastrService.info(err.error.errors);
                            this.router.navigate(['/login'], { relativeTo: this.activatedRoute });
                            return;
                        }

                        return this.toastrService.error(
                            this.translateService.instant('tostrs.something_went_wrong_please_try_again')
                        );
                    }
                );
            }, 500);
        });
    }

    handleScriptLoading() {
        this.scripts.forEach((item: any) => {
            this.loadScripts(item);
        });
    }

    loadScripts(s) {
        if (this.scriptsLoaded) {
            return;
        }

        const script = document.createElement('link');
        script.rel = 'stylesheet';
        script.href = s?.src;
        script.crossOrigin = 'anonymous';
        script.onload = () => {
            this.scripts[this.scripts.indexOf(s)].loaded = true;
            if (this.scripts.every((s) => s.loaded)) {
                this.scriptsLoaded = true;
            }
        };
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    downloadRecording(url) {
        var downloadUrl = window.open('', '_top');
        const urlWithByPass = `${url}&ngsw-bypass=true`; //by passing the req to show download progress
        this.userService.getFinalTempAuthUrl({ finalURL: urlWithByPass }).subscribe(
            (res: any) => {
                downloadUrl.location = res.finalURL;
                this.toastrService.info(this.translateService.instant('tostrs.recorded_meeting_downloaded'));
            },
            (err) => {
                this.loggerService.log(err?.error, 'error while fetching recording stream url');
                if (err?.error?.message === 'URL_EXPIRED') {
                    this.toastrService.error(err.error.errors);
                } else {
                    this.toastrService.info(
                        this.translateService.instant('tostrs.something_went_wrong_please_try_again')
                    );
                }
            }
        );
    }

    copyRecordingLink(url) {
        navigator.clipboard.writeText(url);
        this.toastrService.info(this.translateService.instant('tostrs.recording_link_copied'));
        if (this.url_text && this.url_text.nativeElement) {
            this.url_text.nativeElement.textContent = this.translateService.instant('inside_call.call_info.copied');
        }

        setTimeout(() => {
            if (this.url_text && this.url_text.nativeElement) {
                this.url_text.nativeElement.textContent = this.translateService.instant('invitepopup.copy_url');
            }
        }, 3000);
    }

    getFormattedDate(date, showToday) {
        let recordedDate = new Date(date);
        return showToday && isToday(parseISO(date))
            ? `${this.translateService.instant(
                  `website_recording_page.dayNames.${this.currentDay}`
              )}, ${this.convertToLocaleDatePipe.transform(date, 'd MMM y')}`
            : `${this.translateService.instant(
                  `website_recording_page.dayNames.${recordedDate.getDay()}`
              )}, ${this.convertToLocaleDatePipe.transform(date, 'd MMM y')}`;
    }
}

import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    NgZone,
    OnDestroy,
    ViewEncapsulation,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitterService, EventData, ProfileService, UserService, MeetingService } from 'src/app/core';
import { WEBINAR_ATTENDEE_EVENTS } from '../../../webinar-attendee/constants/webinar-attendee-events';
import moment from 'moment';
import * as _ from 'lodash';
import { EventUserRole } from 'src/app/constants/webinar-enum';
import { APP_EVENTS } from 'src/app/constants';
import { FloatingReactionService } from '../../services/floating-reaction.service';
import { LiveChatService } from 'src/app/core/services/live-chat.service';

export interface SessionInvitedMember {
    _id: string;
    name: string;
    lastName: string;
    role: string;
    status?: string;
    socialMediaLinks?: any;
    registered: boolean;
    meetingId: string;
    firstName: string;
    userId: string;
    cOn: string;
    mOn: string;
    __v: number;
    isDeleted?: boolean;
}
@Component({
    selector: 'app-schedule-view',
    templateUrl: './schedule-view.component.html',
    styleUrls: ['./schedule-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ScheduleViewComponent implements OnInit, OnChanges, OnDestroy {
    @Output() toggle: EventEmitter<boolean> = new EventEmitter();
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() hideMobileSchedule: EventEmitter<boolean> = new EventEmitter();
    @Input() sessionDetails;
    @Input() joinDetails;
    @Input() isMobile;
    @Input() userRole: EventUserRole = EventUserRole.ATTENDEE;
    @Input() activeMeetingId;
    webinarId;
    subscriptions: Subscription[] = [];
    sessionInvitedMembers: SessionInvitedMember[];
    eventDate;
    eventDay;
    member;
    joinBackStageBtnClick = false;
    sessionToJoin;
    showMySessions = false;
    userSessions = [];

    sliceOptions = {
        start: 0,
        end: 180,
        default: 100
    };
    currentUser;
    loading: boolean = true;
    broadcastingInterval;
    prevSessionDetails = {};
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private zone: NgZone,
        public profileService: ProfileService,
        private eventEmitterService: EventEmitterService,
        private userService: UserService,
        private meetingService: MeetingService,
        private floatingService: FloatingReactionService,
        private liveChatService: LiveChatService
    ) {}

    ngOnInit() {
        this.webinarId = this.activatedRoute.parent.snapshot.params.shID;
        this.sessionInvitedMembers = _.get(this.joinDetails, 'speakers');
        this.eventDate = moment(this.joinDetails?.scheduledStartTime).format('DD MMM');
        this.eventDay = moment(this.joinDetails?.scheduledStartTime).format('ddd');
        this.currentUser = this.userService.getUserSync();
        // if user role is attendee then replace joinDetails meeting id with active meeting id
        if (this.userRole === EventUserRole.ATTENDEE) {
            this.joinDetails.meetingId = this.activeMeetingId;
        }
        this.subscriptions.push(
            this.eventEmitterService.subscribe((event: EventData) => {
                this.zone.run(() => {
                    switch (event.type) {
                        case WEBINAR_ATTENDEE_EVENTS.SHOW_SCHEDULE:
                            this.toggle.emit(true);
                            break;
                        case WEBINAR_ATTENDEE_EVENTS.HIDE_SCHEDULE:
                            this.closeView();
                            break;
                        case APP_EVENTS.USER_LEFT:
                            if (this.joinBackStageBtnClick) {
                                this.joinAsSpeaker();
                            }
                            break;
                    }
                });
            })
        );
        this.setSessionDetails();
    }

    ngOnChanges(changes: SimpleChanges) {
        // do not entertain on first change
        if (changes['sessionDetails']?.firstChange) return;
        if (this.userRole === EventUserRole.ATTENDEE) {
            this.joinDetails.meetingId = this.activeMeetingId;
        }
        if (changes['sessionDetails']?.currentValue && changes['sessionDetails']?.previousValue) {
            this.sessionInvitedMembers = _.get(this.joinDetails, 'speakers');
            // set prevSessions
            _.forEach(changes['sessionDetails']?.previousValue, (session) => {
                this.prevSessionDetails[session._id] = session;
            });
            this.setSessionDetails(true);
        }
    }

    getSessionBreakDuration(endTime, startTime) {
        var diff = (new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
    }

    getInvitedMemberProfile(member) {
        if (member && !member?.profileUrl) {
            member['participantInitials'] = this.profileService.getInitials(
                `${member.firstName} ${member.lastName}`,
                member?.userId
            );
            const circleColorInfo = this.profileService.getColorForInitials(
                `${member.firstName} ${member.lastName}`,
                member?.userId
            );
            member['colorChosen'] = circleColorInfo?.bg;
        }
        return member;
    }

    getInviteDetails(invitedMembers, memberList) {
        if (!_.isEmpty(invitedMembers)) {
            var self = this;
            _.forEach(invitedMembers, (invitedMember) => {
                self.member = _.find(self.sessionInvitedMembers, ['_id', invitedMember]);
                if (self.member) {
                    self.member = self.getInvitedMemberProfile(self.member);
                    memberList.push(self.member);
                }
            });
        }
    }

    setSessionDetails(polling = false) {
        this.userSessions = [];
        if (!this.currentUser) return;
        var currentTime = new Date().getTime();
        // first check if i m invited as speaker
        const meAsSpeakerInfo = this.joinDetails?.speakers?.find(
            (speaker) => speaker.userId && speaker.userId === this.currentUser._id
        );
        let showMySessionOnly = false;
        _.forEach(this.sessionDetails, (session) => {
            var startTime = new Date(session?.startTime).getTime();
            var endTime = new Date(session?.endTime).getTime();
            if (session?.type != 'break') {
                session['status'] = this.meetingService.getEventStatus(session);
            } else {
                session['breakTime'] = this.getSessionBreakDuration(endTime, startTime);
            }
            session['sTime'] = moment(session?.startTime).format('LT');
            session['eTime'] = moment(session?.endTime).format('LT');
            session['date'] = moment(session?.startTime).format('DD MMM');

            // check the prev state of showing session details

            session['showSessionDetails'] = this.prevSessionDetails[session._id]?.showSessionDetails ?? false;
            if (this.isMobile && session.status !== 'Completed') {
                session['showSessionDetails'] = true;
            }
            session['speakersInMobile'] = this.prevSessionDetails[session._id]?.speakersInMobile ?? false;
            session['speakers'] = [];
            session['coHosts'] = [];
            this.getInviteDetails(_.get(session, 'invitedSpeakers'), _.get(session, 'speakers'));
            this.getInviteDetails(_.get(session, 'invitedCoHosts'), _.get(session, 'coHosts'));

            //

            if (meAsSpeakerInfo && this.userRole !== EventUserRole.ATTENDEE) {
                if (session.invites?.indexOf(meAsSpeakerInfo._id) !== -1) {
                    this.userSessions.push(session);
                    if (this.joinDetails.meetingId === session.meetingId) {
                        showMySessionOnly = true;
                    }
                }
            }
        });
        if (this.userSessions?.length && showMySessionOnly && !polling) {
            this.handleToggle();
        }
        this.loading = false;
    }

    closeView() {
        this.toggle.emit(false);
        this.close.emit();
    }

    showHideSessionDetails(session) {
        session.showSessionDetails = !session.showSessionDetails;
        session.speakersInMobile = false;
    }

    showSpeakersInMobile(session) {
        session['speakersInMobile'] = true;
    }

    hideSpeakersInMobile(session) {
        session['speakersInMobile'] = false;
    }

    hideSchedule() {
        this.hideMobileSchedule.emit(true);
    }

    handleToggle(e = null) {
        this.showMySessions = !this.showMySessions;
    }

    joinAsSpeaker() {
        //Need Desktop routing
        window.location.href = `${this.sessionToJoin?.meetingUrl}&isSession=1&autoJoin=1&parentMeetingId=${this.sessionToJoin.parentMeetingId}`;
    }

    navigateToWebinarSession(sessionDetail) {
        this.floatingService.showReactions = false;
        this.liveChatService.resetMessageData();
        // if (this.userRole === EventUserRole.SPEAKER) {
        //     this.joinBackStageBtnClick = true;
        //     this.sessionToJoin = sessionDetail;
        //     this.eventEmitterService.emit({
        //         type: APP_EVENTS.IN_CALL_JOIN_BACKSTAGE,
        //         data: { sessionDetail }
        //     });
        // } else if (sessionDetail.enableJoin) {
        //     this.router.navigate(
        //         [`attendee/${sessionDetail.parentMeetingId}/join/${this.activatedRoute.snapshot.params.hash}`],
        //         {
        //             queryParams: {
        //                 sessionId: sessionDetail.meetingId,
        //                 autoJoin: true
        //             }
        //         }
        //     );
        // }

        // TODO: enable join
        // join backstage from incall schedule
        this.joinBackStageBtnClick = true;
        this.sessionToJoin = sessionDetail;
        // this.eventEmitterService.emit({
        //     type: APP_EVENTS.IN_CALL_JOIN_BACKSTAGE,
        //     data: sessionDetail
        // });
        this.eventEmitterService.emit({
            type: APP_EVENTS.NAVIGATE_TO_MAINSTAGE_ON_SESSION_SELECT,
            data: sessionDetail
        });
    }

    onExpandText(evt: any): void {
        this.sliceOptions.end = this.sliceOptions.end ? undefined : this.sliceOptions.default;
    }

    trackByFunction(index, item) {
        return item._id;
    }

    ngOnDestroy() {
        clearInterval(this.broadcastingInterval);
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { AuthService } from './../services/auth.service';
import { Observable } from 'rxjs';
import { UserService, AppService, SystemService } from '../services';
import { JiocloudAuthService } from '../services/jiocloud-auth.service';
import { GuestAuthService } from '../services/guest-auth.service';
import { TranslateService } from '@ngx-translate/core';
import { WebinarAttendeeService } from 'src/app/webinar-attendee';
import { AppLoggerService } from '../services/app-logger.service';
import { JmTranslateService } from '../services/jm-translate.service';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    private deviceId;
    constructor(
        private authService: AuthService,
        private userService: UserService,
        private jiocloudAuthService: JiocloudAuthService,
        private guestAuthService: GuestAuthService,
        private systemService: SystemService,
        private translate: TranslateService,
        private webinarAttendeeService: WebinarAttendeeService,
        private appLoggerService: AppLoggerService,
        private appService: AppService,
        private jmTranslateService: JmTranslateService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.jmTranslateService.isTranslateUrl(request.url)) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'e6fwJNulx5KwyBXo1vHKzOP0QPgu3F2'
                }
            });
            return next.handle(request);
        }
        if (this.appLoggerService.isCloudLoggingUrl(request.url)) {
            return next.handle(request);
        }

        // jio cloud
        if (this.jiocloudAuthService.isJioCloudRequest(request) && !request.url.includes('addons')) {
            if (
                this.jiocloudAuthService.getAuthInfo() &&
                !['/security/users/logout', '/account/external/login', '/account/token/refresh'].filter(
                    (path) => request.url.indexOf(path) > -1
                ).length
            ) {
                request = request.clone({
                    headers: this.jiocloudAuthService.getCommonHeaders(
                        request.headers,
                        request.headers.get('X-Meeting-Id')
                    )
                });
            }
            return next.handle(request);
        }
        // jiomeet
        if (
            request.url.indexOf('/api') > -1 &&
            (!request.url.includes('addons') || this.appService.getConfigVariable('useAuthTokenForAddonsApi'))
        ) {
            const authInfo = this.authService.getAuthInfo();
            if (authInfo) {
                this.deviceId = request?.url?.includes('streamurl')
                    ? `attendee${this.systemService.getDeviceUUID()}`
                    : this.systemService.getDeviceUUID();

                // this.deviceId = this.systemService.getDeviceUUID();
                request = request.clone({
                    setHeaders: {
                        Authorization: authInfo.jwt,
                        deviceid: this.deviceId,
                        'ngsw-bypass': 'true', //TODO: Needs to be moved new interceptor (Added this to get the progress for upload api's)
                        'accept-language': this.translate.currentLang || 'en'
                    }
                });
                if (this.userService.getUserSync().email || this.userService.getUserSync().phoneNo) {
                    request = request.clone({
                        setHeaders: {
                            'X-Identity': this.userService.getUserSync().email || this.userService.getUserSync().phoneNo
                        }
                    });
                }
                if (request?.url?.includes('getstreamurl') && request?.url?.includes('public=true')) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: 'null'
                        }
                    });
                }
            }

            // Guest User
            else if (this.guestAuthService.getAuthInfo() && this.guestAuthService.isGuestRequest(request)) {
                request = request.clone({
                    setHeaders: {
                        Authorization: this.guestAuthService.getAuthInfo().jwt,
                        deviceid: this.systemService.getDeviceUUID(),
                        'accept-language': this.translate.currentLang || 'en'
                    }
                });
                return next.handle(request);
            }

            request = request.clone({
                setHeaders: {
                    deviceid: this.deviceId || this.systemService.getDeviceUUID(),
                    'accept-language': this.translate.currentLang || 'en'
                }
            });

            const rilAuthInfo = this.authService.getRILAuthInfo();
            let rilJwt = null;
            if (rilAuthInfo && rilAuthInfo.jwt) {
                rilJwt = rilAuthInfo.jwt;
                if (
                    ['/api/verifyotp', '/api/authorize/google', '/api/authorize/facebook', '/api/sign/signup'].filter(
                        (path) => request.url.indexOf(path) > -1
                    ).length
                ) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: rilJwt
                        }
                    });
                }
            }

            if (
                (this.webinarAttendeeService.isInviteOnlyEvent ||
                    !authInfo ||
                    this.webinarAttendeeService.getIsIFrameAutoJoinAttendee()) &&
                this.webinarAttendeeService.getGuestAttendeeToken()
            ) {
                request = request.clone({
                    setHeaders: {
                        Authorization: this.webinarAttendeeService.getGuestAttendeeToken().jwt,
                        deviceid: this.systemService.getDeviceUUID(),
                        'accept-language': this.translate.currentLang || 'en'
                    }
                });
                return next.handle(request);
            }
        }

        return next.handle(request);
    }
}

import { DatePipe } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { addMonths, addMinutes, differenceInHours, differenceInMinutes, isLastDayOfMonth, getDay } from 'date-fns';
import { WebinarService } from 'src/app/dashboard-webinars/services/webinar.service';
import { PadPipe } from 'src/app/shared/pipes/pad.pipe';
import { UtilService } from 'src/app/core';
import { Subscription } from 'rxjs';
import { RecurringService } from 'src/app/core/services/recurring.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-recurring-events-popup',
    templateUrl: './recurring-events-popup.component.html',
    styleUrls: ['./recurring-events-popup.component.scss'],
    providers: [PadPipe]
})
export class RecurringEventsPopupComponent implements OnInit {
    @Output() close = new EventEmitter();
    @Output() recurringData = new EventEmitter();
    @ViewChild('calendar') startTimeCalendar;
    @Input() startTime;
    @Input() endTime;
    @Input() meeting;
    @Input() singleEdit;
    recurringForm: FormGroup;
    singleEditForm: FormGroup;
    timeForm: FormGroup;
    recurringTypeOptions = ['daily', 'weekly', 'monthly'];
    monthWeekOptions = ['First', 'Second', 'Third', 'Fourth', 'Last'];
    monthDayOptions = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    meetingStartTime = new Date();
    selectedRecurringType = 'daily';
    weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    selectedDaysOfWeek = [];
    todaysDayIndex = 0;
    selectedMonthType = 'monthWeek';
    message: string;
    startTimeString = 'Select Time';
    durationString = 'Select Duration';
    selectedDaysOfMonth: any[];
    selectedWeek: any[];
    hourValues = [...Array(13).keys()];
    hours: any;
    minuteValues = [...Array(60).keys()];
    minutes: any;
    durationHourValues = [...Array(24).keys()];
    durationHours;
    durationMinuteValues = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
    durationMinutes;
    durationInHours: number;
    durationInMinutes: number;
    previousForm: any = {};
    subscriptions: Subscription[] = [];

    constructor(
        private fb: FormBuilder,
        private zone: NgZone,
        private datePipe: DatePipe,
        private padPipe: PadPipe,
        private webinarService: WebinarService,
        private utilService: UtilService,
        private recurringService: RecurringService,
        private toasterService: ToastrService
    ) {}

    ngOnInit(): void {
        this.setTodaysIndex();
        this.previousForm = this.recurringService.getPreviousFormData();
        if (!this.singleEdit) {
            if (this.previousForm) {
                this.previousRecurringForm();
            } else {
                this.createRecurringForm();
            }
        } else {
            this.createSingleEditForm();
        }
    }

    previousRecurringForm() {
        this.recurringForm = this.fb.group({
            repeat: [this.recurringTypeOptions[this.getRepeatType()]],
            interval: [this.previousForm.interval || 1],
            daysOfWeek: this.fb.array(this.getMeetingSelectedDays()),
            monthlyType: this.setSelectedMonthType(this.previousForm.monthlyType),
            monthWeekType: [this.monthWeekOptions[this.getMeetingMonthWeek()]],
            monthDayType: [this.monthDayOptions[this.getMeetingMonthDay()]],
            dayOfMonth: this.fb.array(this.getSelectedDaysOfMonth(this.todaysDayIndex)),
            dateInMonth: [this.previousForm.dateInMonth || this.startTime.getDate()],
            lastDayOfMonth: [this.meeting?.repeatOptions?.lastDayOfMonth || isLastDayOfMonth(new Date())],
            weeksInMonth: this.fb.array(this.previousForm.weeksInMonth || this.getSelectedWeek(0)),
            endDate: this.previousForm.endDate
        });
        this.listenToFormValueChanges();
    }

    createRecurringForm() {
        this.recurringForm = this.fb.group({
            repeat: [
                this.meeting && this.meeting?.repeatOptions?.repeat !== 'none'
                    ? this.recurringTypeOptions[this.getRepeatType()]
                    : this.recurringTypeOptions[0]
            ],
            interval: [this.meeting?.repeatOptions?.interval || 1],
            daysOfWeek: this.fb.array(
                this.meeting?.repeatOptions?.daysOfWeek &&
                    this.meeting?.repeatOptions?.daysOfWeek.filter((d) => d).length
                    ? this.getMeetingSelectedDays()
                    : this.getDefaultSelectedDays()
            ),
            monthlyType: this.setSelectedMonthType(
                this.meeting && this.meeting.repeatOptions && 'dateInMonth' in this.meeting.repeatOptions
                    ? this.meeting.repeatOptions.dateInMonth !== 0
                        ? 'monthDate'
                        : 'monthWeek'
                    : 'monthWeek'
            ),
            monthWeekType: [
                this.meeting?.repeatOptions?.weeksInMonth
                    ? this.monthWeekOptions[this.getMeetingMonthWeek()]
                    : this.monthWeekOptions[0]
            ],
            monthDayType: [
                this.meeting?.repeatOptions?.daysOfWeek &&
                this.meeting?.repeatOptions?.daysOfWeek.filter((d) => d).length
                    ? this.monthDayOptions[this.getMeetingMonthDay()]
                    : this.monthDayOptions[this.todaysDayIndex]
            ],
            dayOfMonth: this.fb.array(this.getSelectedDaysOfMonth(this.todaysDayIndex)),
            dateInMonth: [this.meeting?.repeatOptions?.dateInMonth || this.startTime.getDate()],
            lastDayOfMonth: [this.meeting?.repeatOptions?.lastDayOfMonth || isLastDayOfMonth(new Date())],
            weeksInMonth: this.fb.array(this.meeting?.repeatOptions?.weeksInMonth || this.getSelectedWeek(0)),
            endDate:
                this.meeting?.repeatOptions?.repeat !== 'none' && this.meeting?.repeatOptions?.endDate
                    ? new Date(this.meeting.repeatOptions?.endDate?.substring(0, 10))
                    : this.getDefaultEndDate(this.selectedRecurringType)
        });
        this.listenToFormValueChanges();
    }

    getMeetingMonthWeek() {
        return this.previousForm?.weeksInMonth.indexOf(1) || this.meeting?.repeatOptions?.weeksInMonth?.indexOf(1);
    }

    getMeetingMonthDay() {
        return this.previousForm?.daysOfWeek.indexOf(true) || this.meeting?.repeatOptions?.daysOfWeek?.indexOf(true);
    }

    getMeetingSelectedDays() {
        this.selectedDaysOfWeek = this.previousForm?.daysOfWeek || this.meeting?.repeatOptions?.daysOfWeek;
        return this.selectedDaysOfWeek;
    }

    setSelectedMonthType(type) {
        this.selectedMonthType = type;
        return this.selectedMonthType;
    }

    createSingleEditForm() {
        this.hours = this.convertToObjArr(this.hourValues);
        this.minutes = this.convertToObjArr(this.minuteValues);
        this.durationHours = this.convertToObjArr(this.durationHourValues);
        this.durationMinutes = this.convertToObjArr(this.durationMinuteValues);

        const startDate = new Date(this.startTime);
        const endDate = new Date(this.endTime);
        const hours = startDate.getHours();
        const minutes = startDate.getMinutes();
        // let durationInHours = 0;
        // let durationInMinutes = 30;
        this.startTimeString = `${this.datePipe.transform(new Date(), 'h:mm a')}`;

        this.durationInHours = differenceInHours(new Date(this.endTime), new Date(this.startTime));
        this.durationInMinutes = differenceInMinutes(new Date(this.endTime), new Date(this.startTime));
        this.durationInMinutes -= this.durationInHours * 60;

        this.singleEditForm = this.fb.group({
            startTime: [startDate, [Validators.required]],
            endTime: [endDate, [Validators.required]],
            duration: this.fb.group({
                hour: [hours > 12 ? hours - 12 : hours, [Validators.required]],
                minutes: [minutes, [Validators.required]],
                meridiem: [hours > 11 ? 'pm' : 'am', [Validators.required]],
                durationInHours: [this.durationInHours, [Validators.required]],
                durationInMinutes: [this.durationInMinutes, [Validators.required]]
            })
        });
        this.durationString = `${
            this.durationInHours ? this.padPipe.transform(this.durationInHours) + ' hours ' : ''
        }${this.padPipe.transform(this.durationInMinutes)} minutes`;
        this.listenToFormValueChanges();
        this.setEndDate(this.singleEditForm.value.startTime);
    }

    ngAfterViewInit() {
        document.querySelectorAll('.time-input').forEach((node) =>
            node.addEventListener('click', (e) => {
                if (node.querySelector('.time-input .dropdown-menu').contains(e.target as any)) {
                    if ((e.target as any).classList.contains('custom-button')) {
                        return true;
                    } else {
                        e.stopImmediatePropagation();
                        e.stopPropagation();
                        return false;
                    }
                }
            })
        );
    }

    getRepeatType() {
        let type = this.previousForm?.repeat || this.meeting?.repeatOptions.repeat;
        this.selectedRecurringType = type;
        let index = this.recurringTypeOptions.indexOf(type);
        return index;
    }

    setEndDate(startTime) {
        let { endTime, duration } = this.singleEditForm.value;
        let { hour, minutes, meridiem, durationInHours, durationInMinutes } = duration;

        hour = +hour;

        if (![startTime, hour, minutes, durationInHours, durationInMinutes].includes(null)) {
            let hoursToSet;
            if (meridiem === 'am') {
                hoursToSet = hour === 12 ? 0 : hour;
            } else {
                hoursToSet = hour === 12 ? hour : hour + 12;
            }
            startTime.setHours(hoursToSet);
            startTime.setMinutes(+minutes);
            endTime = addMinutes(new Date(startTime), +durationInHours * 60 + +durationInMinutes);
            this.singleEditForm.get('endTime').setValue(endTime);
            this.startTimeString = `${hour}:${this.padPipe.transform(minutes)} ${meridiem}`;
            this.durationString = `${
                durationInHours ? this.padPipe.transform(durationInHours) + ' hours ' : ''
            }${this.padPipe.transform(durationInMinutes)} minutes`;
        }
    }

    convertToObjArr(arr) {
        return arr.map((a) => {
            return {
                label: a,
                value: a
            };
        });
    }

    getSelectedWeek(i) {
        this.selectedWeek = Array(5).fill(0);
        this.selectedWeek[i] = 1;
        return this.selectedWeek;
    }

    listenToFormValueChanges() {
        if (!this.singleEdit) {
            this.recurringForm.get('repeat').valueChanges.subscribe((type) => {
                this.selectedRecurringType = type;
                this.recurringForm.patchValue({
                    endDate:
                        this.meeting?.repeatOptions?.repeat !== 'none' && this.meeting?.repeatOptions?.endDate
                            ? new Date(this.meeting.repeatOptions?.endDate?.substring(0, 10))
                            : this.getDefaultEndDate(this.selectedRecurringType)
                });
            });
            this.recurringForm.get('monthlyType').valueChanges.subscribe((type) => {
                this.selectedMonthType = type;
            });
            this.recurringForm.get('monthDayType').valueChanges.subscribe((day) => {
                let indexOfDay = this.monthDayOptions.indexOf(day);
                this.recurringForm.patchValue({
                    dayOfMonth: this.getSelectedDaysOfMonth(indexOfDay)
                });
            });
            this.recurringForm.get('monthWeekType').valueChanges.subscribe((week) => {
                let indexOfWeek = this.monthWeekOptions.indexOf(week);
                this.recurringForm.patchValue({
                    weeksInMonth: this.getSelectedWeek(indexOfWeek)
                });
            });
        }

        if (this.singleEdit) {
            this.singleEditForm.get('startTime').valueChanges.subscribe((startTime) => {
                this.setEndDate(startTime);
            });
            this.singleEditForm
                .get('duration')
                .get('durationInHours')
                .valueChanges.subscribe((hour) => {
                    this.durationMinuteValues =
                        +hour === 23 ? [0, 5, 10, 15, 20, 25, 30] : [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

                    if (this.singleEditForm.get('duration').get('durationInMinutes').value > 30 && +hour == 23) {
                        this.singleEditForm.get('duration').get('durationInMinutes').setValue(30);
                    }
                    this.durationMinutes = this.convertToObjArr(this.durationMinuteValues);
                });
        }
    }

    closePopup() {
        this.close.emit();
    }

    onHideCalendar() {}

    setTodaysIndex() {
        this.todaysDayIndex = this.getDefaultSelectedDays().findIndex((i) => i);
    }

    getDefaultSelectedDays(startTime = null, values = null) {
        this.selectedDaysOfWeek = Array(7).fill(false);
        this.selectedDaysOfWeek[getDay(startTime ? startTime : new Date())] = true;
        return this.selectedDaysOfWeek;
    }

    getDefaultEndDate(option = null) {
        let monthsToBeAdded = option == 'monthly' ? 12 : option == 'weekly' ? 6 : 3;
        let endDate = addMonths(option == null ? new Date() : new Date(this.startTime), monthsToBeAdded);
        endDate.setHours(23, 59, 59, 999);
        return endDate;
    }

    selectedDays(j) {
        if (this.selectedDaysOfWeek[j] !== true) {
            this.selectedDaysOfWeek[j] = true;
        } else {
            this.selectedDaysOfWeek[j] = false;
        }
        this.recurringForm.patchValue({
            daysOfWeek: this.selectedDaysOfWeek
        });
    }

    getSelectedWeekDays() {
        let allDays = this.selectedDaysOfWeek;
        let selectedDays = '';
        for (let day = 0; day < 7; day++) {
            if (allDays[day] === true) {
                selectedDays += this.getDayFromIndex1(day) + ' ';
            }
        }
        return selectedDays.trim().split(' ').join(', ');
    }

    displayMessage(recurringType) {
        switch (recurringType) {
            case 'daily':
                return 'Occurs every day until ';
            case 'weekly':
                return `Occurs every ${this.getSelectedWeekDays()} each week until `;
            case 'monthly':
                let monthDate =
                    this.previousForm && this.previousForm.dateInMonth !== 0
                        ? this.previousForm.dateInMonth
                        : this.startTime.getDate();
                return `Occurs every ${this.getDayWithSuffix(monthDate)} day of the month until `;
        }
    }

    getDayWithSuffix(day) {
        let suffix = 'th';
        if (day === 1 || day === 21 || day === 31) {
            suffix = 'st';
        } else if (day === 2 || day === 22) {
            suffix = 'nd';
        } else if (day === 3 || day === 23) {
            suffix = 'rd';
        }
        return `${day}${suffix}`;
    }

    getDayFromIndex1(index) {
        return this.monthDayOptions[index];
    }

    updateDaysOfWeek() {
        if (this.recurringForm.value.repeat === 'monthly') {
            this.recurringForm.patchValue({
                daysOfWeek: this.selectedDaysOfMonth,
                dateInMonth: this.selectedMonthType === 'monthDate' ? this.recurringForm.value.dateInMonth : 0
            });
        }
    }

    getSelectedDaysOfMonth(i) {
        this.selectedDaysOfMonth = Array(7).fill(false);
        this.selectedDaysOfMonth[i] = true;
        return this.selectedDaysOfMonth;
    }

    submit() {
        this.updateDaysOfWeek();
        let payload = this.recurringForm.value;
        this.recurringData.emit(payload);
        // this.close.emit();
    }

    submitSingleEditForm() {
        let payload = {
            startTime: this.meeting.startTime,
            endTime: this.meeting.endTime,
            webinar: this.meeting.webinar,
            topic: this.meeting.topic,
            isPinEnabled: this.meeting.isPinEnabled,
            repeatOptions: this.meeting.repeatOptions,
            update: 'current',
            updatedOn: this.meeting.startTime,
            updatedStartTime: this.singleEditForm.value.startTime,
            updatedEndTime: this.singleEditForm.value.endTime,
            participants: []
        };
        this.subscriptions.push(
            this.webinarService.getWebinarDetails(this.meeting._id, true).subscribe(
                (res) => {
                    this.meeting.repeatOptions.repeatUntill = '';
                    this.meeting.repeatOptions.timeZone = '';
                    const participants = res?.participants?.map((u) => u?.userId) || [];
                    payload.participants = participants;
                    this.handleUpdateAPI(payload);
                },
                (err) => {
                    this.toasterService.error(err?.error?.errors || err?.error?.message);
                }
            )
        );
        this.close.emit();
    }

    handleUpdateAPI(payload) {
        this.subscriptions.push(
            this.webinarService
                .updateWebinar(
                    this.meeting._id,
                    Object.assign(payload, {
                        hostInfo: this.meeting.hostInfo,
                        privateIp: this.meeting.privateIp,
                        url: this.meeting.url,
                        vpin: this.meeting.vpin,
                        gatewayIp: this.meeting.gatewayIp,
                        participants: payload?.participants
                    }),
                    this.meeting
                )
                .subscribe(
                    (res: any) => {},
                    (err) => {
                        this.toasterService.error('Cannot update event for past time');
                    }
                )
        );
    }
}

import { Injectable } from '@angular/core';
import { updateLocale } from 'moment-timezone';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root'
})
export class ExploreEventsService {
    private isAuthenticated;
    private from = null;
    private to = null;
    filterobject: Subject<any> = new Subject();
    trendingObject: BehaviorSubject<any> = new BehaviorSubject([]);
    recommendedObject: BehaviorSubject<any> = new BehaviorSubject([]);
    ongoingObject: BehaviorSubject<any> = new BehaviorSubject([]);
    upcomingObject: BehaviorSubject<any> = new BehaviorSubject([]);
    replayObject: BehaviorSubject<any> = new BehaviorSubject([]);
    favouriteDetails: Subject<any> = new Subject();
    filterEvents: any;
    viewMoreEventsState: any;

    constructor(private restservice: RestService, private appService: AppService, private authService: AuthService) {
        this.isAuthenticated = this.authService.getIsAuthenticated();
    }
    getEventsList(listtype, eventtype, publishType = 'all', eventDay = 'all', search = '', offset = -1, limit = 0) {
        if (eventDay != 'all') {
            var todayStart = new Date(new Date().setHours(0, 0, 0, 0));
            var todayStart2 = new Date(new Date().setHours(0, 0, 0, 0));
            var tomorrowStart = new Date(todayStart2.setDate(todayStart2.getDate() + 1));
            var nextStart = new Date(todayStart2.setMonth(todayStart2.getMonth() + 1));

            if (eventDay == 'Today') {
                this.from = todayStart.toISOString();
                this.to = tomorrowStart.toISOString();
            } else {
                this.from = tomorrowStart.toISOString();
                this.to = nextStart.toISOString();
            }
        } else {
            this.from = null;
            this.to = null;
        }
        eventtype = encodeURIComponent(eventtype);
        search = encodeURIComponent(search);
        return this.restservice.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/explore${
                this.isAuthenticated ? '' : '/general'
            }?listType=${listtype}&eventType=${eventtype}${
                publishType != 'all' ? `&publishType=${publishType == 'Organisation' ? 'Limited' : 'Public'}` : ''
            }${this.from ? `&from=${this.from}` : ''}${this.to ? `&to=${this.to}` : ''}${
                !!search ? `&search=${search}` : ''
            }${offset != -1 ? `&offset=${offset}` : ''}${limit ? `&limit=${limit}` : ''}`
        );
    }

    postFavourite(id) {
        return this.restservice.post(`${this.appService.getEnvVariable('BASE_URL')}/favourites/meeting`, {
            resourceId: id
        });
    }
    deleteFavourite(id) {
        return this.restservice.delete(`${this.appService.getEnvVariable('BASE_URL')}/favourites/meeting`, {
            body: { resourceId: id }
        });
    }

    setFilterObject(obj) {
        this.filterEvents = obj;
    }

    getFilerObject() {
        return this.filterEvents;
    }

    setViewMoreEventsState(value) {
        this.viewMoreEventsState = value;
    }
}

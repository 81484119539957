import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from './../../../environments/environment';
import * as prestageEnvironment from './../../../environments/environment.prestage';
import * as rcEnvironment from './../../../environments/environment.rc';
import * as prodEnvironment from './../../../environments/environment.pro';
import { LocalStorageService } from './local-storage.service';
import { EventEmitterService } from './event-emitter.service';
import { APP_EVENTS } from 'src/app/constants/app-events';

const THUMBNAIL_SIZE = {
    width: 1244,
    height: 681
};

const FULL_SIZE = {
    width: 1280,
    height: 720
};

@Injectable({
    providedIn: 'root'
})
export class DesktopAppService {
    isDesktopApp = false;
    electronBridge: any;
    domainName: null;
    selectedEnvironment: any;
    developerClickEvtCount: any = 0;
    enableEnvSelection: Boolean = false;
    devModeCount: number = 5;
    public appStoreDistributionBuild = false;
    public showElectronMacStoreUpdatePopup: boolean = false;
    public rcEnvironmentConfig = rcEnvironment?.environment;
    public prestageEnvironmentConfig = prestageEnvironment?.environment;
    public prodEnvironmentConfig = prodEnvironment?.environment;
    public electronAppVersion: any;
    public userId: any;
    public remoteId: any;
    public screenSize;

    constructor(
        private router: Router,
        private localStorageService: LocalStorageService,
        private eventEmitterService: EventEmitterService
    ) {
        this.electronBridge = window['__jioEventsDesktop'];
        this.isDesktopApp = this.electronBridge?.desktop || !!window.navigator.userAgent.match(/Electron/);
        this.domainName = this.electronBridge?.domainName;
        this.handleRedirectLink();
        this.handleFullScreen();
        this.selectedEnvironment = this.localStorageService.getItem('selectedEnvironment');
        this.getAppInfo();
        this.fetchElectronAppVersion();
        window.addEventListener('message', receiveMessage, false);
        function receiveMessage(event) {
            // this check is neccessary // because the `message` handler accepts messages from any URI
            console.log('received response:  ', event.data);
        }
    }

    restore(delay: number) {
        if (this.electronBridge) {
            setTimeout(() => {
                this.electronBridge.restore();
            }, delay);
        }
    }

    enableDevMode() {
        if (
            this.developerClickEvtCount >= 0 &&
            this.developerClickEvtCount <= this.devModeCount &&
            !this.enableEnvSelection
        ) {
            this.developerClickEvtCount++;
            this.enableEnvSelection = this.developerClickEvtCount >= this.devModeCount;
        } else {
            this.developerClickEvtCount = 0;
        }
    }

    reload(url: string = null) {
        if (this.electronBridge) {
            this.electronBridge.reload(url);
        }
    }

    fetchPlatform() {
        if (this.electronBridge) {
            return this.electronBridge.fetchPlatform.platform;
        }
    }

    startCheckForUpdates() {
        if (this.electronBridge && !this.appStoreDistributionBuild) {
            console.log('Check for updated triggered');
            return this.electronBridge.appUpdate.startCheckForUpdates();
        }
    }

    updateCheckStatus(callback) {
        if (this.electronBridge && !this.appStoreDistributionBuild) {
            return this.electronBridge.appUpdate.updateCheckStatus(callback);
        }
    }

    downloadUpdate() {
        if (this.electronBridge && !this.appStoreDistributionBuild) {
            return this.electronBridge.appUpdate.downloadUpdate();
        }
    }

    updateAvailable(callback) {
        if (this.electronBridge && !this.appStoreDistributionBuild) {
            return this.electronBridge.appUpdate.updateAvailable(callback);
        }
    }

    downloadProgressComplete(callback) {
        if (this.electronBridge && !this.appStoreDistributionBuild) {
            return this.electronBridge.appUpdate.downloadProgressComplete(callback);
        }
    }

    fetchDownloadProgress(callBack) {
        if (this.electronBridge && !this.appStoreDistributionBuild) {
            return this.electronBridge.appUpdate.downloadProgressUpdate(callBack);
        }
    }

    installAppUpdate(opts) {
        if (this.electronBridge) {
            console.log('triggered installing app from web');
            return this.electronBridge.appUpdate.installAppUpdate(opts);
        }
    }

    async isDevicePermissionAllowed(deviceType: string) {
        var permission = await this.fetchSystemPreferences(deviceType);
        console.log('permission status' + permission);
        return permission === 'granted';
    }

    isDarwin() {
        return this.fetchPlatform() === 'darwin';
    }

    ismacOSDesktop() {
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        const platform = window.navigator.platform;
        console.log('PLATFORM::', platform);
        return macosPlatforms.indexOf(platform) !== -1 ? true : false;
    }

    fetchElectronAppVersion() {
        if (this.electronBridge && !this.electronAppVersion) {
            this.electronBridge.fetchVersion.version().then((version) => {
                this.electronAppVersion = version;
            });
        }
    }

    fetchScreenSizes() {
        if (this.electronBridge) {
            return this.electronBridge.fetchScreenSize.screen().then((size) => {
                this.screenSize = size?.size;
                this.screenSize['scaleFactor'] = size.scaleFactor;
                return;
            });
        }
    }

    async fetchSystemPreferences(type) {
        if (this.electronBridge) {
            return await this.electronBridge.systemPreferences.getMediaAccessStatus(type);
        }
    }

    reloadWebContent(url: string = null) {
        if (this.electronBridge) {
            this.electronBridge.reloadWebContent(url);
        }
    }

    handleKeyEvents(type, evt) {
        if (this.electronBridge) {
            this.electronBridge.handleKeyEvents(type, evt);
        }
    }

    setMeetingInProgress(value: boolean) {
        if (this.electronBridge) {
            this.electronBridge.setMeetingInProgress(value);
        }
    }

    setContentProtection(value: boolean) {
        if (this.electronBridge) {
            this.electronBridge.setContentProtection(value);
        }
    }

    isMacAccessibilityPermissionGranted() {
        if (this.electronBridge) {
            const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
            const platform = window.navigator.platform;
            console.log('PLATFORM::', platform);
            return this.ismacOSDesktop() ? this.electronBridge.isMacAccessibilityPermissionGranted() : true;
        } else {
            return false;
        }
    }

    handleFullScreen() {
        if (this.electronBridge) {
            this.electronBridge.handleFullScreen((event, isFullScreen) => {
                console.log('fullscreen callback from electron');
                // this.eventEmitterService.emit({
                //     type: APP_EVENTS.FULL_SCREEN_EVENT,
                //     data: isFullScreen
                // });
            });
        }
    }

    getAppInfo() {
        if (this.electronBridge) {
            const info = this.electronBridge.getAppInfo();
            if (info) {
                this.electronAppVersion = info.version;
                this.appStoreDistributionBuild = info.appStoreDistribution || false;
            }
        }
    }

    handleRedirectLink() {
        if (this.electronBridge) {
            this.electronBridge.handleRedirectLink((event, url, isDeepLink) => {
                console.log('redirect link callback from electron: ' + url);
                var parsedUrl = new URL(url);
                var meetingId = parsedUrl.searchParams.get('meetingId');
                var pwd = parsedUrl.searchParams.get('pwd');
                var pathName = parsedUrl.pathname.substring(1);
                if (isDeepLink && meetingId) {
                    this.router.navigate(['join'], {
                        queryParams: {
                            meetingId: meetingId,
                            pwd: pwd
                        }
                    });
                } else {
                    // this.router.navigate([pathName], {
                    //     queryParams: paramsToObject(parsedUrl.searchParams.entries())
                    // });
                }
                event.sender.send('handle-redirect-link-reply', 'redirected to' + pathName + parsedUrl.searchParams);
            });
        }
    }

    async launchAuthWindow(url, cb) {
        if (this.electronBridge) {
            await this.electronBridge.launchAuthWindow(url, cb);
            // cb(null, res);
            var listener = (ev) => {
                console.log('received auth callback from electron: ' + ev.data.url);
                cb(ev.data.url);
                window.removeEventListener('message', listener);
            };
            window.addEventListener('message', listener, false);
        } else {
            cb(new Error('bridge is not available'));
        }
    }

    async launchEventWindow(url, cb) {
        if (this.electronBridge) {
            await this.electronBridge.launchEventWindow(url, cb);
            // cb(null, res);
            var listener = (ev) => {
                console.log('received auth callback from electron: ' + ev.data.url);
                cb(ev.data.url);
                window.removeEventListener('message', listener);
            };
            window.addEventListener('message', listener, false);
        } else {
            cb(new Error('bridge is not available'));
        }
    }
    getScreenShareSources(sdk) {
        this.electronBridge.askScreenShare(sdk);
    }

    async getScreenCaptureSources(opts: any) {
        var desktopCapturer: any = this.electronBridge.desktopCapturer;
        var sources = await desktopCapturer
            .getSources(opts)
            .then((sourcesList) => {
                // Handle the sources
                return sourcesList;
            })
            .catch((error) => {
                // Handle the error (e.g., permission denied)
                console.log('permission denied');
            });
        return sources;
    }

    async getScreenShareTrack(source) {
        try {
            var config: any = { audio: false };
            config.video = {
                mandatory: {
                    chromeMediaSource: 'desktop',
                    chromeMediaSourceId: source.id,
                    minWidth: 1280,
                    maxWidth: 1280,
                    minHeight: 720,
                    maxHeight: 720
                }
            };

            const stream = await navigator.mediaDevices.getUserMedia(config);
            return stream;
        } catch (e) {
            console.log(e);
        }
    }

    async prepareStream(source, opts?: any) {
        try {
            var config: any = { audio: false };
            var size = opts?.fullSize ? FULL_SIZE : THUMBNAIL_SIZE;
            config.video = {
                mandatory: {
                    chromeMediaSource: 'desktop',
                    chromeMediaSourceId: source.id,
                    minWidth: size.width,
                    maxWidth: size.width,
                    minHeight: size.height,
                    maxHeight: size.height
                }
            };

            const stream = await navigator.mediaDevices.getUserMedia(config);
            return stream;
        } catch (e) {
            console.log(e);
        }
    }
}

function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
        // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
}
